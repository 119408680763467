import { apiSlice } from "../../api/apiSlice";

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    keepUnusedDataFor: 1,
    getCarMake: builder.query({
      query: ({ stateCode, bodyStyle, fuelType, high, low }) => {
        const url = new URLSearchParams({
          stateCode,
          body: bodyStyle === "All" ? "" : bodyStyle ?? "",
          fuel: fuelType === "All" ? "" : fuelType ?? "",
          high,
          low,
        });

        return `/api/v1/car/make?${url.toString()}`;
      },
    }),
    getCarModel: builder.query({
      query: ({ stateCode, make, bodyStyle, fuelType, high, low }) => {
        const url = new URLSearchParams({
          stateCode,
          make: make === "All" ? "" : make ?? "",
          body: bodyStyle === "All" ? "" : bodyStyle ?? "",
          fuel: fuelType === "All" ? "" : fuelType ?? "",
          high,
          low,
        });

        return `/api/v1/car/model?${url.toString()}`;

        // return `/api/v1/car/model?stateCode=${stateCode}&make=${make}`,
      },
    }),
    getCarGrade: builder.query({
      query: ({ stateCode, make, model, bodyStyle, fuelType, high, low }) => {
        const url = new URLSearchParams({
          stateCode,
          make: make === "All" ? "" : make ?? "",
          model: model === "All" ? "" : model ?? "",
          body: bodyStyle === "All" ? "" : bodyStyle ?? "",
          fuel: fuelType === "All" ? "" : fuelType ?? "",
          high,
          low,
        });

        return `/api/v1/car/grade?${url.toString()}`;

        // `/api/v1/car/grade?stateCode=${stateCode}&make=${make}&model=${model}`,
      },
    }),
    getCarChart: builder.query({
      query: ({ stateCode, make, model, grade, timeline }) =>
        `/api/v1/car/search-by-model?stateCode=${stateCode}&make=${encodeURIComponent(
          make
        )}&model=${encodeURIComponent(model)}&grade=${encodeURIComponent(
          grade
        )}&timeline=${timeline}`,
    }),

    getCarBodyStyle: builder.query({
      query: ({ stateCode }) => `/api/v1/car/body-style?stateCode=${stateCode}`,
    }),
    getCarPriceRange: builder.query({
      query: ({ stateCode, bodyStyle }) =>
        `/api/v1/car/price-range?stateCode=${stateCode}&body=${encodeURIComponent(
          bodyStyle
        )}`,
    }),
    getCarFuelType: builder.query({
      query: ({ stateCode, bodyStyle, high, low }) =>
        `/api/v1/car/fuel-type?stateCode=${stateCode}&body=${encodeURIComponent(
          bodyStyle
        )}&high=${high}&low=${low}`,
    }),

    getCarChartCriteria: builder.query({
      query: ({
        stateCode,
        make,
        model,
        grade,
        timeline,
        body,
        high,
        low,
        fuel,
      }) => {
        const url = new URLSearchParams({
          stateCode,
          make: make === "All" ? "" : make ?? "",
          model: model === "All" ? "" : model ?? "",
          grade: grade === "All" ? "" : grade ?? "",
          timeline,
          body: body === "All" ? "" : body ?? "",
          high,
          low,
          fuel: fuel === "All" ? "" : fuel ?? "",
        });

        return `/api/v1/car/search-by-criteria?${url.toString()}`;

        // return `/api/v1/car/search-by-criteria?stateCode=${stateCode}&make=${encodeURIComponent(
        //   make
        // )}&model=${encodeURIComponent(model)}&grade=${encodeURIComponent(
        //   grade
        // )}&timeline=${timeline}&body=${encodeURIComponent(
        //   body
        // )}&high=${high}&low=${low}&fuel=${encodeURIComponent(fuel)}`;
      },
    }),
  }),
});

export const {
  useGetCarMakeQuery,
  useGetCarModelQuery,
  useGetCarGradeQuery,
  useGetCarChartQuery,
  useGetCarBodyStyleQuery,
  useGetCarPriceRangeQuery,
  useGetCarFuelTypeQuery,
  useGetCarChartCriteriaQuery,
} = configApiSlice;
