import moment from "moment";
import Highcharts from "highcharts";

import { formatCurrency } from "@/helpers/utils";
import { colors } from "@/constants/colors";

export function getTooltipValue(val) {
  const { make, model, grade } = val;
  if (make === "All" && model === "All" && grade === "All") return "All";
  else if (make !== "All" && model === "All" && grade === "All") return make;
  else if (make !== "All" && model !== "All" && grade === "All") return model;
  else if (make !== "All" && model !== "All" && grade !== "All") return grade;
}

function getTickInterval(timeLine, isTablet) {
  if (timeLine === "1M") return 7 * 24 * 3600 * 1000;
  else if (timeLine === "6M") return 7 * 24 * 3600 * 1000 * 4;
  else if (timeLine === "1Y") {
    return isTablet ? 7 * 24 * 3600 * 1000 * 8 : 7 * 24 * 3600 * 1000 * 4;
  } else {
    const monthIndex = new Date().getMonth();
    if (monthIndex < 3) {
      // 1 week
      return 7 * 24 * 3600 * 1000 * 4;
    } else if (monthIndex >= 3 && monthIndex <= 7) {
      // 1 month
      return 7 * 24 * 3600 * 1000 * 4;
    } else if (monthIndex > 7) {
      // 2 months
      return 7 * 24 * 3600 * 1000 * 8;
    }
  }
}

function getFillColor(series) {
  return {
    linearGradient: [0, 0, 0, 300],
    stops: [
      [0, colors[0]],
      [1, Highcharts.color(colors[0]).setOpacity(0).get("rgba")],
    ],
  };
}

function getChartOptions(series, isTablet, timeLine) {
  let chartOptions = {
    title: {
      text: "",
    },
    chart: {
      type: series.length > 1 ? "areaspline" : "area",
    },
    xAxis: {
      // categories: getCategories(timeLine, chartData),
      type: "datetime",

      tickInterval: getTickInterval(timeLine, isTablet),

      labels: {
        formatter: function () {
          if (timeLine === "1M") {
            return moment(this.value).format("MMM DD");
          } else {
            return moment(this.value).format("MMM YY");
          }
        },
      },
    },

    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          return formatCurrency({
            price: this.value,
          }); // Return other values as is
        },
        enabled: !isTablet,
      },
    },
    tooltip: {
      backgroundColor: "#DBEAF6",
      padding: 10,
      borderWidth: 1,
      borderColor: "#4D4D4D",
      formatter: function () {
        return `<div>
        <p>${this.series.name}</p><br /><p>${formatCurrency({
          price: this.y,
        })}</p><br /><p>${moment(this.x).format("Do MMMM YYYY")}</p>
      </div>`;
      },
    },

    plotOptions: {
      area: {
        lineWidth: 1.8,
        fillColor: getFillColor(series),
        fillOpacity: 0.75,
        threshold: null,
        // fillColor: {
        //   linearGradient: [0, 0, 0, 0],
        //   stops: [],
        // },
      },
      areaspline: {
        lineWidth: 1.8,
        // fillColor: getFillColor(series),
        // fillOpacity: 0,
        threshold: null,
        fillColor: {
          linearGradient: [0, 0, 0, 0],
          stops: [],
        },
      },
    },
    series: series,
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  };

  return chartOptions;
}

export { getChartOptions };
