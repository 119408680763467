import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./api/apiSlice";
import { combineReducers } from "redux";
import userStateReducer from "./features/userState/userStateSlice";
import carStateReducer from "./features/car/carStateSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  blacklist: [apiSlice.reducerPath, "carState"],
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  userState: userStateReducer,
  carState: carStateReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function setUpStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(apiSlice.middleware),
    devTools: true,
  });

  setupListeners(store.dispatch);

  return store;
}

export const store = setUpStore();

export let persistor = persistStore(store);
