import React, { useEffect } from "react";
import * as Yup from "yup";
import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Form, Formik } from "formik";
import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormInput from "@/components/FormInput";
import FormTextarea from "@/components/FormTextarea";
import FormSelect from "@/components/FormSelect";
import { useCreateContactRequestMutation } from "@/app/features/contact/contactApiSlice";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";
import { useParams } from "react-router-dom";
import AdCarousel from "@/components/ad-caraousel";

const Heading = styled("h1")(({ theme }) => ({
  fontSize: "24px",
  letterSpacing: "0.16px",
  fontWeight: 700,
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
    marginBottom: "16px",
    marginTop: "40px",
  },
}));

const SubmitBtn = styled(Button)(({ theme }) => ({
  fontSize: "16px",
  letterSpacing: "0.16px",
  width: "100%",
  padding: "8xp 0",
  textAlign: "center",
  fontWeight: 400,
  color: "#FBFCFF",

  borderRadius: "4px",
  background: "#4A98D3",

  ":hover": {
    background: "#4A98D3",
  },
  ":disabled": {
    color: "#FBFCFF",
    opacity: 0.5,
    background: "#4A98D3",
  },
}));

// login validationSchema
const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(5).max(50).label("Name").trim(),
  email: Yup.string().required().min(5).max(255).email().label("Email").trim(),
  category: Yup.string().required().min(5).max(50).label("Category").trim(),
  message: Yup.string().required().min(2).max(2000).label("Message").trim(),
});

const initialValues = {
  name: "",
  email: "",
  category: "",
  message: "",
};

const ContactPage = () => {
  useAnalyticsTitle(pageContent.contact.title);

  // useEffect(() => {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  const { state: stateChoiceFromUrl } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [createContactRequest, { isLoading }] =
    useCreateContactRequestMutation();

  return (
    <Container
      style={{
        paddingBottom: "72px",
        paddingTop: "40px",
        maxWidth: "744px",
      }}
      maxWidth="lg"
    >
      <Helmet>
        <title>{pageContent.contact.title}</title>
        {pageContent.contact.description && (
          <meta name="description" content={pageContent.contact.description} />
        )}
      </Helmet>
      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7410912151232043"
        data-ad-slot="2574869169"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins> */}

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      <Heading>Contact</Heading>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            await createContactRequest(values);
            resetForm();
            toast.success(
              "We have received your response. We will get back to you."
            );
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormInput name="name" label="Name" placeholder="Enter Name" />
            <FormInput name="email" label="Email" placeholder="Enter Email" />
            <FormSelect
              style={{
                marginBottom: "16px",
              }}
              label="Category"
              name="category"
              options={[
                "General Query",
                "Media Query",
                "Advertising Query",
                "Report Error",
              ]}
            />
            <FormTextarea
              name="message"
              label="Message"
              placeholder="Write Your Message"
            />
            <SubmitBtn disabled={isLoading} type="submit">
              Send
            </SubmitBtn>
          </Form>
        )}
      </Formik>

      {stateChoiceFromUrl && (
        <Box mt={"32px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </Container>
  );
};

export default ContactPage;
