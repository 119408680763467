import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getChartOptions } from "@/pages/Homepage/components/chartOptions";
import "./Chart.css";

// const CustomTooltip = ({ xValue }) => {
//   // Custom tooltip component
//   return (
//     <div
//       style={{
//         background: "#DBEAF6",
//         padding: "0 15px",
//         border: "1px solid #4D4D4D",
//         borderRadius: "4px",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         gap: "5px",
//       }}
//     >
//       {xValue} test
//     </div>
//   );
// };

const Chart = ({ chartData, timeSelector }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (chartData.length < 1) {
      return;
    }

    const temp = getChartOptions(chartData, isTablet, timeSelector);

    setChartOptions(temp);
  }, [chartData, timeSelector, isTablet]);

  if (chartData.length < 1) return;

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default Chart;
