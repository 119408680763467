import { apiSlice } from "../../api/apiSlice";

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    keepUnusedDataFor: 1,
    updatePriceAnalytics: builder.mutation({
      query: ({ eventType, eventValue, state, eventRef }) => {
        const params = new URLSearchParams({
          eventType,
          eventValue,
          state,
          eventRef,
        });

        return {
          url: "/api/v1/analytics/price-tracker?" + params.toString(),
          method: "GET",
        };
      },
    }),
    updateSponsoredAnalytics: builder.mutation({
      query: ({ eventType, eventValue, state, eventRef }) => {
        const params = new URLSearchParams({
          eventType,
          eventValue,
          state,
          eventRef,
        });

        return {
          url: "/api/v1/analytics/sponsor-tracker?" + params.toString(),
          method: "GET",
        };
      },
    }),

    updateSponsoredAnalyticsViews: builder.mutation({
      query: ({ eventType, eventValue, state, eventRef }) => {
        const params = new URLSearchParams({
          eventType,
          eventValue,
          state,
          eventRef,
        });

        return {
          url: "/api/v1/analytics/sponsor-tracker-views?" + params.toString(),
          method: "GET",
        };
      },
    }),

    updateAdvertisementAnalytics: builder.mutation({
      query: ({ eventType, eventValue, state, eventRef }) => {
        const params = new URLSearchParams({
          eventType,
          eventValue,
          state,
          eventRef,
        });

        return {
          url: `/api/v1/analytics/advertisement-tracker?${params.toString()}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useUpdatePriceAnalyticsMutation,
  useUpdateSponsoredAnalyticsMutation,
  useUpdateSponsoredAnalyticsViewsMutation,
  useUpdateAdvertisementAnalyticsMutation,
} = configApiSlice;
