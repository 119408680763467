const pageContent = {
  home: {
    title:
      "The Beep Australia | Your Ultimate Car Price Tracker and Analysis Tool",
    // title: "The Beep | Australia's #1 automotive pricing & offers tracker",
    description:
      "Explore The Beep Australia - your go-to platform for tracking car prices, analyzing the car market and viewing comprehensive car price graphs. Keep ahead of the curve with TheBeep's insightful automotive pricing data and make informed decisions!",
  },
  about: {
    title: "The Beep | About Us",
    description:
      "Explanation of the various components that make up car pricing in Australia and insights how to get the best deal.",
  },
  advertise: {
    title: "The Beep | Advertise with us",
    description:
      "Explanation of the various components that make up car pricing in Australia and insights how to get the best deal.",
  },
  contact: {
    title: "The Beep | Contact",
    description: "",
  },
  offer: {
    title: "The Beep | Offers & Deals",
    description:
      "Search for the best deals with our free and extensive Australian automotive offers database.",
  },
  pricingworks: {
    title: "The Beep | How Australian Automotive Pricing Works 2024",
    description:
      "Explanation of the various components that make up car pricing in Australia and insights how to get the best deal.",
  },
  privacy: {
    title: "The Beep | Privacy Policy",
    description: "",
  },
  terms: {
    title: "The Beep | Terms Of Use",
    description: "",
  },
};

export default pageContent;
