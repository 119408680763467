import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { toggleSelectedStateModal } from "@/app/features/userState/userStateSlice";
import Dropdown from "@/components/Dropdown/Dropdown";
import { StyledStack } from "@/sections/SelectCarForm/SelectCarDetails";
import { Stack, Typography } from "@mui/material";

const DefaultOfferForm = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Typography
        component="h5"
        sx={{
          padding: 0,
          margin: 0,
          fontWeight: 400,
          fontSize: { xs: "16px", md: "19px" },
          lineHeight: { xs: "24px", md: "34px" },
          letterSpacing: "0.01em",
        }}
      >
        Please make a selection
      </Typography>

      <Stack spacing={3}>
        <Formik
          initialValues={{
            make: "",
            model: "",
            grade: "",
          }}
        >
          <StyledStack>
            <Dropdown
              label="Make"
              name="make"
              options={[]}
              onClick={() => {
                dispatch(toggleSelectedStateModal());
              }}
              background="#4A98D3"
              displayEmpty={true}
            />
            <Dropdown
              label="Model"
              name="model"
              disabled
              options={[]}
              background="#4A98D3"
              displayEmpty={true}
            />
            <Dropdown
              label="Grade"
              name="grade"
              disabled
              options={[]}
              background="#4A98D3"
              displayEmpty={true}
            />
          </StyledStack>
        </Formik>
      </Stack>
    </>
  );
};

export default DefaultOfferForm;
