import React, { useEffect, useRef, useState } from "react";

import "./Dropdown.css";
import { useField, useFormikContext } from "formik";
import { Scrollbar } from "@/components/scrollbar";

const Icon = () => {
  return (
    <svg height="10" width="10" viewBox="0 0 20 20">
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const Dropdown = ({
  placeHolder = "",
  options,
  isMulti,
  isSearchable,
  onChange,
  onClick,
  label,
  name,
  disabled = false,
  background,
  displayEmpty = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();
  const [field, meta, helpers] = useField(name);
  const formik = useFormikContext();

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });
  const handleInputClick = (e) => {
    if (!disabled) setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return (
        <>
          <p>{label}</p>
          <p className="dropdown-selected-value-overview">{field.value}</p>
        </>
      );
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {label}{" "}
          {selectedValue.map((option) => (
            <div key={option.value} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return (
      <>
        <p>{label}</p>
        <p>{field.value}</p>
      </>
    );
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    // onChange(newValue);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    // setSelectedValue(newValue);
    helpers.setValue(newValue);
    helpers.setTouched(true);
    // onChange(newValue);
    if (onChange) onChange();
    if (name === "make")
      if (formik.touched.model || formik.touched.grade) {
        formik.setFieldValue("model", "All");
        formik.setFieldValue("grade", "All");
      }

    if (name === "model")
      if (formik.touched.grade) formik.setFieldValue("grade", "All");
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue === option;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div
      className={`dropdown-container ${disabled && "disabled"}`}
      style={{
        "--clr-primary": background,
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon />
          </div>
        </div>
      </div>
      {showMenu && !displayEmpty && (
        <Scrollbar className="dropdown-menu">
          {isSearchable && (
            <div className="search-box">
              <input value={searchValue} ref={searchRef} />
            </div>
          )}
          <div
            onClick={() => onItemClick("All")}
            key="All"
            className={`dropdown-item ${isSelected("All") && "selected"}`}
          >
            All
          </div>
          {getOptions().map((option) => (
            <div
              onClick={() => onItemClick(option)}
              key={option}
              className={`dropdown-item ${isSelected(option) && "selected"}`}
            >
              {option}
            </div>
          ))}
        </Scrollbar>
      )}
    </div>
  );
};

export default Dropdown;
