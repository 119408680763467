import { styled } from "@mui/material/styles";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import { useGetAdvertisementByStateQuery } from "@/app/features/advertisement/advertisementApiSlice";
import { useParams } from "react-router-dom";
import { Container, Skeleton } from "@mui/material";
import { useUpdateAdvertisementAnalyticsMutation } from "@/app/features/analytics/analyticsApiSlice";
import { IMAGE_CDN_LINK } from "@/constants/links";

const BASE_IMAGE_URL = `${IMAGE_CDN_LINK}/advertisement`;

// "https://thebeep-au.syd1.cdn.digitaloceanspaces.com/advertisement";

const SwiperStyles = styled(Swiper)(({ theme }) => ({}));

const AdCarouselContainer = styled("div")(({ theme }) => ({
  maxWidth: "320px",
  margin: "0 auto",

  [theme.breakpoints.up("md")]: {
    maxWidth: "728px",
  },
}));

// const SwiperContainer = styled("div")(({ theme }) => ({
//   marginBottom: "16px",
// }));

const AdImageWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "50px",

  [theme.breakpoints.up("md")]: {
    height: "90px",
  },
}));

const AdImageDesktop = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",

  display: "none",
  height: "90px",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const AdImageMobile = styled("img")(({ theme }) => ({
  position: "absolute",
  width: "100%",
  display: "block",

  height: "50px",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const AdImageDesktopText = styled("p")(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  right: "0px",
  padding: "2px 6px",

  margin: "0",

  display: "none",
  textTransform: "uppercase",

  background: "#ccd5dd",
  color: "#1b0d04",

  opacity: "0.4",

  fontSize: "8px",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const AdImageMobileText = styled("p")(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  right: "0px",
  padding: "2px 6px",

  display: "block",
  textTransform: "uppercase",

  margin: "0",

  opacity: "0.4",

  background: "#ccd5dd",
  color: "#1b0d04",

  fontSize: "8px",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default function AdCarousel({ bannerType }) {
  const { state: stateChoiceFromUrl } = useParams();

  const { isLoading, data, isError } = useGetAdvertisementByStateQuery({
    stateCode: stateChoiceFromUrl,
    bannerType: bannerType,
  });

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={200} />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={200} />
      </Container>
    );
  }

  const advertisement = data?.activeAdvertisementList;

  if (advertisement && advertisement.length > 0)
    return (
      <SwiperContainerWrapper list={advertisement} state={stateChoiceFromUrl} />
    );

  return <></>;
}

function SwiperContainerWrapper({ list, state }) {
  const [updateAdvertisementAnalytics] =
    useUpdateAdvertisementAnalyticsMutation();

  async function createAdvertisement(index) {
    try {
      await updateAdvertisementAnalytics({
        eventType: "advertisementView",
        eventValue: list[index].adText,
        eventRef: list[index].adLink,
        state,
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AdCarouselContainer>
      <div>
        <SwiperStyles
          modules={[Autoplay, EffectFade]}
          allowTouchMove={false}
          autoplay={{
            delay: 30000,
            disableOnInteraction: false,
          }}
          effect="fade"
          slidesPerView={1}
          onSwiper={async (swiper) => {
            await createAdvertisement(swiper.activeIndex);
          }}
          onSlideChange={async (swiper) => {
            await createAdvertisement(swiper.activeIndex);
          }}
        >
          {list?.map((advertisement) => (
            <SwiperSlide key={advertisement.id}>
              <a
                href={advertisement.adLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  try {
                    updateAdvertisementAnalytics({
                      eventType: "advertisementClick",
                      eventValue: advertisement.adText,
                      eventRef: advertisement.adLink,
                      state,
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <AdImageWrapper>
                  <AdImageDesktop
                    src={`${BASE_IMAGE_URL}/${advertisement.adDesktopBanner}`}
                    alt={advertisement.adDesktopBanner}
                  />
                  <AdImageMobile
                    src={`${BASE_IMAGE_URL}/${advertisement.adMobileBanner}`}
                    alt={advertisement.adMobileBanner}
                  />
                  <AdImageDesktopText>Advertisement</AdImageDesktopText>
                  <AdImageMobileText>Ad</AdImageMobileText>
                </AdImageWrapper>
              </a>
            </SwiperSlide>
          ))}
        </SwiperStyles>
      </div>
    </AdCarouselContainer>
  );
}
