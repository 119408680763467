import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";
import { useParams } from "react-router-dom";
import AdCarousel from "@/components/ad-caraousel";
import { styled } from "@mui/material/styles";
import { AboutUsImage1Icon, AboutUsImage2Icon } from "@/assets/images/aboutus";

const MainContainer = styled("main")(({ theme }) => ({
  width: "88%",
  maxWidth: "1200px",
  margin: "auto",
  marginTop: "20px",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const TopHeaderSection = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  margin: "2rem 0px",
  [theme.breakpoints.down("md")]: {
    margin: "0px 0px 2rem 0px",
  },
}));

const Heading = styled("h3")(({ theme }) => ({
  textAlign: "center",
  fontSize: "25.2px",
  margin: "0px",
  lineHeight: "2rem",
}));

const AboutSectionHeading = styled("h4")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  lineHeight: "2rem",
  margin: "0px",
}));

const TopParagraph = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  fontWeight: 400,
  margin: "0px",
  color: "#000",
  lineHeight: "2rem",
}));

const AboutSectionContainer = styled("section")(({ theme }) => ({
  display: "flex",
  // justifyContent: "center",
  gap: "3rem",
  marginBottom: "1.7rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

const TextContainer = styled("div")(({ theme }) => ({
  flex: "62%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  flex: "38%",
}));

const AboutImage1 = styled("img")(({ theme, hidden }) => ({
  width: "100% ",
  height: "100% !important",
  objectFit: "cover",
  display: !hidden ? "none" : "block",
  [theme.breakpoints.down("md")]: {
    display: hidden ? "none" : "block",
  },
}));

const AboutImage2 = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  // flex: "50%",
}));

const AboutPage = () => {
  const { state: stateChoiceFromUrl } = useParams();

  useAnalyticsTitle(pageContent.about.title);

  return (
    <>
      <Helmet>
        <title>{pageContent.about.title}</title>
        {pageContent.about.description && (
          <meta name="description" content={pageContent.about.description} />
        )}
      </Helmet>

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      <MainContainer>
        <TopHeaderSection>
          <Heading>About Us</Heading>
          <TopParagraph>
            <strong>The Beep</strong> is a revolutionary way to research new
            cars in Australia, rapidly becoming one of the country’s
            fastest-growing automotive websites. We offer an innovative and
            streamlined experience to search, compare specifications, pricing,
            and find the best deals available—completely free, no subscriptions
            and no data required.
          </TopParagraph>
          <TopParagraph>
            We put consumers first, offering a one-stop platform designed to
            simplify your car research and purchasing journey. Unlike other
            automotive websites, we blend cutting-edge technology with a
            user-centric interface that empowers you to make informed decisions
            quickly and easily - without any hidden costs.
          </TopParagraph>
        </TopHeaderSection>
        <AboutSectionContainer>
          <TextContainer>
            <TextContainer>
              <AboutSectionHeading>What Makes Us Different</AboutSectionHeading>
              <TopParagraph>
                <b>One Platform For All Your Research:</b> Easily compare specs,
                performance, pricing, and deals.
              </TopParagraph>
              <TopParagraph>
                <b>Market Insights:</b> Identify trends and understand the
                market better before you buy.
              </TopParagraph>
              <TopParagraph>
                <b>Search National Offers:</b> Find the best deals across
                Australia’s automotive landscape.
              </TopParagraph>
              <TopParagraph>
                <b>Completely Free:</b> No subscriptions, no user data
                collection—just pure information.
              </TopParagraph>
              <TopParagraph>
                <b>User-Friendly Design:</b> A simple, responsive interface
                accessible across all devices.
              </TopParagraph>
            </TextContainer>
            {/* <img src="" alt="" srcset="" /> */}
            <AboutImage1
              src={AboutUsImage1Icon}
              alt="About Us Image 1"
              hidden={false}
            />
            <TextContainer>
              <AboutSectionHeading>
                A Comprehensive Database
              </AboutSectionHeading>
              <TopParagraph>
                We’ve built an extensive, searchable database covering over 40
                car brands and more than 1,200 models. Whether you’re looking
                for the performance of a sports car, the fuel efficiency of a
                hybrid or the range of an EV, The Beep provides the detailed
                specs you need.
              </TopParagraph>
              <TopParagraph>
                <b>Specifications:</b> Performance, fuel usage, EV range, safety
                ratings and more.
              </TopParagraph>
              <TopParagraph>
                <b>Pricing:</b> Recommended drive-away pricing tailored for each
                state.
              </TopParagraph>
              <TopParagraph>
                <b>Offers:</b> Stay up to date with current and past national
                sales campaigns.
              </TopParagraph>
            </TextContainer>
          </TextContainer>
          <ImageContainer>
            <AboutImage1
              src={AboutUsImage1Icon}
              alt="About Us Image 1"
              hidden
            />
          </ImageContainer>
        </AboutSectionContainer>
        <AboutSectionContainer>
          <TextContainer>
            <AboutSectionHeading>Our Vision For The Future</AboutSectionHeading>
            <TopParagraph>
              We’re just getting started! Our goal is to become the go-to
              destination for car research in Australia, helping you navigate
              the automotive world with ease. As we grow, we’ll continue to
              innovate—expect new features, more data, and tools that empower
              you to make the best car-buying decisions.
            </TopParagraph>
            <TopParagraph>
              The Beep was jointly founded by Rob Leigh and Shane Riley who
              share a combined 35+ years of industry experience. Their knowledge
              and passion for cars drive it’s innovation, making it a trusted
              and consumer-focused platform. It is proudly Australian-owned and
              completely independent.
            </TopParagraph>
          </TextContainer>
          <ImageContainer>
            <AboutImage2 src={AboutUsImage2Icon} alt="About Us Image 2" />
          </ImageContainer>
        </AboutSectionContainer>
        <section>
          <AboutSectionHeading>Ready To Get Started?</AboutSectionHeading>
          <TopParagraph>
            Begin your search today and explore the best car deals across
            Australia! With The Beep, car research has never been easier or more
            accessible.
          </TopParagraph>
        </section>
      </MainContainer>

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </>
  );
};

export default AboutPage;
