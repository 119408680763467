import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";
import { useParams } from "react-router-dom";
import AdCarousel from "@/components/ad-caraousel";
import {
  GoAutoIcon,
  AutoTalkIcon,
  CareExpertIcon,
  DailyTelegraphIcon,
  GoAuto2Icon,
  NewscomauIcon,
  NightlyIcon,
  WhichCarIcon,
} from "@/assets/images/media";

const mediaLinkList = [
  {
    link: "https://www.google.com/",
    title:
      "The Beep website helping Australians find cars for sale more easily",
    description: "Channel 7 News Australia, March 2024",
    image: "",
  },
  {
    link: "https://www.news.com.au/technology/motoring/motoring-news/how-chinese-evs-will-change-the-car-market/news-story/40a7666c45c9adc8eb8788d2fde2a592",
    title: "Price plunge as cheap EVs flood Australia",
    description: "news.com.au, August 2024",
    image: NewscomauIcon,
  },
  {
    link: "https://www.whichcar.com.au/news/hot-drive-away-deals-from-mitsubishi-during-september",
    title: "Hot drive-away deals from Mitsubishi during September",
    description: "Which Car?, September 2024",
    image: WhichCarIcon,
  },
  {
    link: "https://www.dailytelegraph.com.au/motoring/motoring-news/market-reality-hits-electric-cars-as-prices-crash/news-story/ca1203234f2e849c0b8e0a7d8385cca3",
    title: "‘Market reality’ hits electric cars as prices crash",
    description: "Daily Telegraph, May 2024",
    image: DailyTelegraphIcon,
  },
  {
    link: "https://thenightly.com.au/opinion/gemma-acton-high-fuel-costs-could-shift-ev-sales-into-top-gear-c-14413147",
    title: "High fuel costs could shift EV sales into top gear",
    description: "The Nightly, April 2024",
    image: NightlyIcon,
  },
  {
    link: "https://goauto.com.au/news/market-insight/market-insight-2024/market-insight-zooming-in-on-pricing-picture/2024-04-09/93537.html",
    title: "Market Insight: Zooming in on pricing picture",
    description: "Go Auto News Premium, April 2024",
    image: GoAuto2Icon,
  },
  {
    link: "https://www.carexpert.com.au/car-news/australias-67-billion-new-car-obsession",
    title: "Australia’s $67 billion new car obsession",
    description: "Car Expert, April 2024",
    image: CareExpertIcon,
  },
  {
    link: "https://autotalk.com.au/industry-news/vehicle-price-tracker-is-central-to-the-beep",
    title: "Vehicle price tracker is central to The Beep",
    description: "AutoTalk, April 2024",
    image: AutoTalkIcon,
  },
  {
    link: "https://premium.goauto.com.au/new-site-for-transparent-new-car-prices/",
    title: "New site for transparent new-car prices",
    description: "Go Auto News Premium, March 2024",
    image: GoAutoIcon,
  },
];

const MainContainer = styled("main")(({ theme }) => ({
  width: "80%",
  maxWidth: "1200px",
  margin: "auto",
  marginTop: "20px",
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Heading = styled("h3")(({ theme }) => ({
  textAlign: "center",
  fontSize: "25.2px",
  marginBottom: "2rem",
}));

const TopParagraph = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  marginBottom: "16px",
  [theme.breakpoints.up("md")]: {
    marginBottom: "24px",
    marginTop: "20px",
  },
}));

const MediaSection = styled("section")(({ theme }) => ({
  width: "80%",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  rowGap: "2rem",
  marginTop: "1rem",
  marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: "0px",
  },
}));

const MediaContainer = styled("div")(({ theme }) => ({
  // width: "80%",
  gap: "2rem",
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "1rem",
  },
}));

const MediaTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    marginTop: "0.5rem",
  },
}));

const MediaLinkHeading = styled("h4")(({ theme }) => ({
  fontSize: "19px",
  margin: "0px",
}));

const MediaLinkParagraph = styled("p")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "5px",
}));

const MediaLinkImage = styled("img")(({ theme }) => ({
  height: "150px",
  width: "250px",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const MediaLinkIFrame = styled("iframe")(({ theme }) => ({
  height: "150px",
  width: "250px",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const MediaPage = () => {
  const { state: stateChoiceFromUrl } = useParams();

  useAnalyticsTitle(pageContent.pricingworks.title);

  return (
    <>
      <Helmet>
        <title>{pageContent.pricingworks.title}</title>
        {pageContent.pricingworks.description && (
          <meta
            name="description"
            content={pageContent.pricingworks.description}
          />
        )}
      </Helmet>

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      <MainContainer>
        <section>
          <Heading>Media Appearances</Heading>
          <TopParagraph>
            Explore our collection of appearances across various platforms, from
            television to print, where we delve into topics ranging from the
            market trends to car pricing. Discover our latest insights and
            contributions in the media landscape.
          </TopParagraph>
        </section>
        <MediaSection>
          {mediaLinkList.map((mediaLink) => (
            <MediaContainer>
              {mediaLink.image ? (
                <a href={mediaLink.link} rel="noreferrer" target="_blank">
                  <MediaLinkImage src={mediaLink.image} alt={mediaLink.title} />
                </a>
              ) : (
                <MediaLinkIFrame
                  // width="420"
                  // height="345"
                  src="https://www.youtube.com/embed/55FdF85XG6o?si=jsC39JDOhpPNDBbj"
                ></MediaLinkIFrame>
              )}
              <MediaTextContainer>
                <MediaLinkHeading>{mediaLink.title}</MediaLinkHeading>
                <MediaLinkParagraph>{mediaLink.description}</MediaLinkParagraph>
              </MediaTextContainer>
            </MediaContainer>
          ))}
        </MediaSection>
      </MainContainer>
      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </>
  );
};

export default MediaPage;
