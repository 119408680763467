import { DEFAULT__CHART_FILTER } from "@/constants/filters";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const convertToArray = (v) => {
  if (!v) {
    return undefined;
  }
  return typeof v.split(",") === "string" ? [v] : v.split(",");
};

function getAllUrlParams(params) {
  const state = params.get("state");
  const make = convertToArray(params.get("make"));
  const model = convertToArray(params.get("model"));
  const grade = convertToArray(params.get("grade"));
  const timeline = params.get("timeline");
  const filterType = params.get("filterType") ?? "model";

  const bodyStyle = convertToArray(params.get("bodyStyle"));
  const priceRange = convertToArray(params.get("priceRange"));
  const fuelType = convertToArray(params.get("fuelType"));

  if (state && make && model && grade && timeline) {
    return {
      isValid: true,
      values: {
        state,
        make,
        model,
        grade,
        timeline,
        bodyStyle,
        priceRange,
        fuelType,
        filterType,
      },
    };
  } else {
    return {
      isValid: false,
      values: {},
    };
  }
}

export const useCarLocation = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { isValid, values } = getAllUrlParams(searchParams);

  const handleSelectState = (stateCode) => {
    // path = offer-tracker | price-tracker
    const page = pathname.split("/")[1];

    if (page === "offer-tracker") {
      const urlParams = {
        make: ["All"],
        offer: "current",
      };

      const url = new URLSearchParams(urlParams);
      navigate(`${page}/state/${stateCode}?${url.toString()}`);
    } else if (page === "price-tracker") {
      // const urlParams = {
      //   make: ["All"],
      //   model: ["All"],
      //   grade: ["All"],
      //   state: stateCode,
      //   timeline: DEFAULT__CHART_FILTER,
      //   filterType: "model",
      // };

      // const url = new URLSearchParams(urlParams);
      // navigate(`${page}/state/${stateCode}?${url.toString()}`);
      if (values.filterType === "criteria") {
        // setSearchParams({
        //   make: ["All"],
        //   model: ["All"],
        //   grade: ["All"],
        //   state: stateCode,
        //   timeline: DEFAULT__CHART_FILTER,
        //   filterType: values.filterType,
        //   bodyStyle: ["All"],
        //   priceRange: ["All"],
        //   fuelType: ["All"],
        // });

        const urlParams = {
          make: ["All"],
          model: ["All"],
          grade: ["All"],
          state: stateCode,
          timeline: DEFAULT__CHART_FILTER,
          filterType: "criteria",
          bodyStyle: ["All"],
          priceRange: ["All"],
          fuelType: ["All"],
        };

        const url = new URLSearchParams(urlParams);
        navigate(`${page}/state/${stateCode}?${url.toString()}`);
      } else {
        // setSearchParams({
        //   make: ["All"],
        //   model: ["All"],
        //   grade: ["All"],
        //   state: stateCode,
        //   timeline: DEFAULT__CHART_FILTER,
        //   filterType: values.filterType,
        // });

        const urlParams = {
          make: ["All"],
          model: ["All"],
          grade: ["All"],
          state: stateCode,
          timeline: DEFAULT__CHART_FILTER,
          filterType: "model",
        };
        const url = new URLSearchParams(urlParams);
        navigate(`${page}/state/${stateCode}?${url.toString()}`);
      }
    } else {
      navigate(`${page}/state/${stateCode}`);
    }
  };

  const updateSearchParams = (name, value, index) => {
    let tValues = { ...values };
    if (values.filterType === "model") {
      tValues = {
        make: values.make,
        model: values.model,
        grade: values.grade,
        state: values.state,
        timeline: values.timeline,
        filterType: values.filterType,
      };
    }

    if (index !== undefined) {
      const temp = [...tValues[name]];
      temp[index] = value;
      tValues[name] = temp;

      if (name === "make") {
        if (tValues["model"][index] !== "All") {
          tValues["model"][index] = "All";
        }
        if (tValues["grade"][index] !== "All") {
          tValues["grade"][index] = "All";
        }
      }

      if (name === "model") {
        if (tValues["grade"][index] !== "All") {
          tValues["grade"][index] = "All";
        }
      }

      if (values.filterType === "criteria") {
        if (name === "bodyStyle") {
          if (tValues["priceRange"][index] !== "All") {
            tValues["priceRange"][index] = "All";
          }

          if (tValues["fuelType"][index] !== "All") {
            tValues["fuelType"][index] = "All";
          }

          if (tValues["make"][index] !== "All") {
            tValues["make"][index] = "All";
          }
          if (tValues["model"][index] !== "All") {
            tValues["model"][index] = "All";
          }
          if (tValues["grade"][index] !== "All") {
            tValues["grade"][index] = "All";
          }
        }

        if (name === "priceRange") {
          if (tValues["fuelType"][index] !== "All") {
            tValues["fuelType"][index] = "All";
          }

          if (tValues["make"][index] !== "All") {
            tValues["make"][index] = "All";
          }
          if (tValues["model"][index] !== "All") {
            tValues["model"][index] = "All";
          }
          if (tValues["grade"][index] !== "All") {
            tValues["grade"][index] = "All";
          }
        }

        if (name === "fuelType") {
          if (tValues["make"][index] !== "All") {
            tValues["make"][index] = "All";
          }
          if (tValues["model"][index] !== "All") {
            tValues["model"][index] = "All";
          }
          if (tValues["grade"][index] !== "All") {
            tValues["grade"][index] = "All";
          }
        }
      }
    } else {
      tValues[name] = value;
    }

    const url = new URLSearchParams(tValues);
    // console.log(url.toString());
    navigate(`?${url.toString()}`);
  };

  const updatePriceRange = (name, value, index) => {
    const tValues = { ...values };

    if (index !== undefined) {
      const temp = [...tValues[name]];
      temp[index] = value;
      tValues[name] = temp;
    } else {
      tValues[name] = value;
    }

    const url = new URLSearchParams(tValues);
    // console.log(url.toString());
    navigate(`?${url.toString()}`);
  };

  const handlePriceRangeChange = (index) => {
    // reset the filters after price range to "all" when price rnage changes

    const tValues = { ...values };

    if (tValues["fuelType"][index] !== "All") {
      tValues["fuelType"][index] = "All";
    }

    if (tValues["make"][index] !== "All") {
      tValues["make"][index] = "All";
    }
    if (tValues["model"][index] !== "All") {
      tValues["model"][index] = "All";
    }
    if (tValues["grade"][index] !== "All") {
      tValues["grade"][index] = "All";
    }

    // console.log(name, value);

    const url = new URLSearchParams(tValues);
    // console.log(url.toString());
    navigate(`?${url.toString()}`);
  };

  const handleCarAddition = () => {
    let tValues = { ...values };

    const c = tValues.make.length;
    if (c > 3) return;

    const tMake = [...tValues.make, "All"];
    const tModel = [...tValues.model, "All"];
    const tGrade = [...tValues.grade, "All"];

    tValues = {
      ...tValues,
      make: tMake,
      model: tModel,
      grade: tGrade,
    };

    if (values.filterType === "criteria") {
      const tBodyStyle = [...tValues.bodyStyle, "All"];
      const tFuelType = [...tValues.fuelType, "All"];
      const tPriceRange = [...tValues.priceRange, "All"];

      tValues = {
        ...tValues,
        bodyStyle: tBodyStyle,
        fuelType: tFuelType,
        priceRange: tPriceRange,
      };
    }

    const url = new URLSearchParams(tValues);

    navigate(`${pathname}?${url.toString()}`);
  };

  const handleCarDeletion = (index) => {
    let tValues = { ...values };

    if (index < 0) {
      return;
    }

    const tMake = tValues.make.filter((v, i) => i !== index);
    const tModel = tValues.model.filter((v, i) => i !== index);
    const tGrade = tValues.grade.filter((v, i) => i !== index);

    tValues = {
      ...tValues,
      make: tMake,
      model: tModel,
      grade: tGrade,
    };

    if (values.filterType === "criteria") {
      const tBodyStyle = tValues.bodyStyle.filter((v, i) => i !== index);
      const tFuelType = tValues.fuelType.filter((v, i) => i !== index);
      const tPriceRange = tValues.priceRange.filter((v, i) => i !== index);

      tValues = {
        ...tValues,
        bodyStyle: tBodyStyle,
        fuelType: tFuelType,
        priceRange: tPriceRange,
      };
    }

    const url = new URLSearchParams(tValues);
    // console.log(url.toString());
    navigate(`?${url.toString()}`);
  };

  return {
    isValid,
    values,
    updateSearchParams,
    handleCarAddition,
    handleCarDeletion,
    handleSelectState,
    updatePriceRange,
    handlePriceRangeChange,
  };
};
