import React from "react";
import { styled } from "@mui/material/styles";
import { useField } from "formik";

const Input = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "8px 16px",

  fontSize: "16px",
  //   fontWeight: "400",
  fontWeight: 275,

  ":placeholder": {
    lineHeight: "160%",
    letterSpacing: "0.08px",
  },
}));

const InputWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  marginBottom: "16px",
}));

const ErrorText = styled("span")(({ theme }) => ({
  width: "100%",
  fontSize: "13px",
  fontStyle: "italic",
  fontWeight: 300,
  lineHeight: "160%",
  letterSpacing: "0.065px",
  color: "#EC4D3F",
}));

const FormInput = ({ name, label, placeholder, type }) => {
  const [field, meta, helpers] = useField(name);

  //   console.log(field, meta, helpers);

  return (
    <InputWrapper>
      <label style={{ display: "none" }} htmlFor={name}>
        {label}
      </label>
      <Input {...field} type={type} name={name} placeholder={placeholder} />
      {meta.touched && meta.error ? <ErrorText>{meta.error}</ErrorText> : null}
    </InputWrapper>
  );
};

export default FormInput;
