import { useState } from "react";
import ShareModal from "@/layouts/HomeLayout/share-modal";
import ShareIcon from "@mui/icons-material/Share";
import { Button, Stack } from "@mui/material";

const ShareCarButton = () => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const handleShareModalClose = () => setIsShareModalVisible(false);

  return (
    <Stack
      direction="row"
      sx={{ width: "100%" }}
      justifyContent="center"
      mt={1}
    >
      <ShareModal
        open={isShareModalVisible}
        handleClose={handleShareModalClose}
      />
      <Button
        sx={{
          color: "#000",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
        }}
        startIcon={<ShareIcon />}
        onClick={() => setIsShareModalVisible(true)}
      >
        Share
      </Button>
    </Stack>
  );
};

export default ShareCarButton;
