import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Modal from "@/components/modal";
import states, { stateMap } from "@/constants/states";
// import SelectDemo from "@/components/Dropdown/SelectDemo";
import { colors } from "@/constants/colors";
import { useCarLocation } from "@/hooks/use-car-location";
import useCarStore from "@/context/carStore";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsSelectStateModalVisible,
  toggleSelectedStateModal,
} from "@/app/features/userState/userStateSlice";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { TheBeepIcon } from "@/assets/icons";
import StateSelectionDropdown from "@/components/state-selection-dropdown";

const LogoStyles = styled("img")`
  display: block;
  max-width: 150px;
  margin: 0 auto;
`;

const Heading = styled("h1")`
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  margin: 0px;
`;

const Description = styled("p")`
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  margin: 0px;
`;

export const StateSelectionModal = () => {
  const dispatch = useDispatch();
  const isSelectStateModalVisible = useSelector(
    selectIsSelectStateModalVisible
  );

  const params = useParams();
  const selectedState = params.state;
  const { handleSelectState } = useCarLocation();
  const { resetChart } = useCarStore();
  const [stateDropdownValue, setStateDropdownValue] = useState(
    stateMap[selectedState]
  );

  const options = states.map((state) => state.name);

  const onChange = (name, value) => {
    setStateDropdownValue(value);
    const state = states.find((state) => state.name === value);
    handleSelectState(state.code);
    resetChart();
    localStorage.setItem("state", state.code);
    dispatch(toggleSelectedStateModal());
  };

  const handleClose = () => {
    if (!selectedState) return;
    dispatch(toggleSelectedStateModal());
  };

  return (
    <Modal
      open={isSelectStateModalVisible}
      onClose={handleClose}
      style={{
        maxWidth: "420px",
        p: 0,
        borderRadius: "8px",
        border: "none",
        outline: "none",
      }}
      hideCloseIcon
    >
      <Box
        p={3}
        sx={{ display: "flex", flexDirection: "column", rowGap: "1.5rem" }}
      >
        <LogoStyles
          // src="/assets/logos/logo-beep-dark.png"
          src={TheBeepIcon}
          alt="thebeep"
          height={43}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "0px",
            padding: "0px",
            rowGap: "10px",
          }}
        >
          <Heading>Please Select Your State</Heading>
          <Description>
            We use your selection to calculate the drive away pricing most
            relevant to you
          </Description>
        </Box>
        {/* <Box mt={4}> */}
        <StateSelectionDropdown
          label="State"
          name="state"
          defaultOption={[]}
          options={options}
          background={colors[0]}
          onChange={onChange}
          value={stateDropdownValue || stateMap[selectedState]}
        />
        {/* </Box> */}
      </Box>
    </Modal>
  );
};
