import {
  showSelectedStateModal,
  toggleSelectedStateModal,
} from "@/app/features/userState/userStateSlice";
import { DEFAULT_STATE } from "@/constants/states";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
  useSearchParams,
} from "react-router-dom";

const DefaultLayout = ({ route }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const outlet = useOutlet();
  const { state: stateChoiceFromUrl } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stateChoiceFromUrl) {
      const persistedStateChoice = localStorage.getItem("state");
      if (persistedStateChoice) {
        navigate(`/${route}/state/${persistedStateChoice}`);
      }
      // else {
      //   navigate(`/${route}/state/${DEFAULT_STATE}`);
      // }
      else {
        dispatch(showSelectedStateModal());
      }
    }
  }, [location, searchParams, stateChoiceFromUrl, route, navigate]);

  return outlet;
};

export default DefaultLayout;
