import React from "react";
import { styled } from "@mui/material/styles";
import LineChart from "@/pages/Homepage/components/LineChart";
import OverviewBox from "@/pages/Homepage/components/OverviewBox";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const StyledChartWrapper = styled("div")(({ theme }) => ({
  // display: "flex",
  marginTop: "20px",
  // flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    // flexDirection: "row",
    gap: "50px",
  },

  "& .chart-container": {
    // width: "100%",

    // [theme.breakpoints.down("lg")]: {
    // },

    [theme.breakpoints.up("md")]: {
      // width: "65%",
      // alignSelf: "flex-start",
      position: "sticky",
      top: "100px",
    },
  },

  "& .overview-container": {
    // flex: 1,
    height: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "20px",

    // [theme.breakpoints.up("lg")]: {
    //   borderLeft: "2px solid #e6e6e6",
    //   paddingLeft: "20px",
    //   paddingTop: "20px",
    // },
  },
}));

const ChartWrapper = () => {
  return (
    <StyledChartWrapper>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={8}>
          <LineChart />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <OverviewBox />
        </Grid2>
      </Grid2>
    </StyledChartWrapper>
  );
};

export default ChartWrapper;
