const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

export const privacyLink = `${ADMIN_URL}privacy-policy`;
export const termsLink = `${ADMIN_URL}terms-conditions`;

export const IMAGE_CDN_LINK = "https://cdn.cdn.thebeep.com.au";

export const resourcesoptions = [
  {
    link: "/howpricingworks/state",
    name: "How Pricing Works",
  },
];

export const companyoptions = [
  {
    link: "/aboutus/state",
    name: "About Us",
  },
  {
    link: "/media/state",
    name: "Media",
  },
  {
    link: "/advertise/state",
    name: "Advertise With Us",
  },
  {
    link: "/contact/state",
    name: "Contact",
  },
];
