import { createSlice } from "@reduxjs/toolkit";
// import jwt_decode from "jwt-decode";

const carStateSlice = createSlice({
  name: "carState",
  initialState: {
    chartData: [
      // {
      //   name: 0,
      //   data: [],
      //   color: "#4A98D3",
      // },
    ],
    timeline: "YTD",
    loading: false,
    carAverageMinAndMax: {},
  },
  reducers: {
    updateChartData(state, action) {
      const { index, data, color, name, carAverageMinAndMax } = action.payload;

      if (state.chartData.length < 1) {
        state.chartData = [{ data, color, name, carAverageMinAndMax }];
      } else {
        const newChartData = [...state.chartData];
        // console.log(newChartData);
        newChartData[index] = { data, color, name, carAverageMinAndMax };
        // console.log(newChartData);

        return {
          ...state,
          chartData: newChartData,
        };
      }
    },

    upadateChartTimeline(state, action) {
      const { timeline } = action.payload;
      // state.timeline = timeline;

      return {
        ...state,
        timeline: timeline,
      };
    },

    toggleChartLoading(state) {
      return {
        ...state,
        loading: !state.loading,
      };
    },

    // toggleSelectedStateModal: (state) => {
    //   state.isSelectStateModalVisible = !state.isSelectStateModalVisible;
    // },
  },
});

export const { updateChartData, upadateChartTimeline, toggleChartLoading } =
  carStateSlice.actions;

export default carStateSlice.reducer;
