import PropTypes from "prop-types";
import {
  Box,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { HamburgerIcon, LocationIcon } from "@/assets/icons";
import { SIDE_NAV_WIDTH } from "@/constants/navbar";
import { Link, useLocation, useParams } from "react-router-dom";
import { stateMap } from "@/constants/states";
import { MenuDropdown } from "@/layouts/HomeLayout/top-nav-menu";
import { useSelector } from "react-redux";
import { selectIsSelectStateModalVisible } from "@/app/features/userState/userStateSlice";
import { companyoptions, resourcesoptions } from "@/constants/links";

const TOP_NAV_HEIGHT = 64;

const LogoStyles = styled("img")`
  display: block;
  max-width: 150px;
  /* margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
`;

// const LinkStyles = styled(Link)`
//   color: #fff;
//   text-decoration: none;

//   &:hover {
//     color: #4a98d3;
//   }
// `;

const LinkStyles = styled(Link)(({ theme, active }) => ({
  color: active ? "#4a98d3" : "#fff",
  textDecoration: "none",
  "&:hover": {
    color: "#4a98d3",
  },
}));

const IconButtonStyles = styled(IconButton)(({ theme, active }) => ({
  border: "none",
  outline: "none",
  background: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  padding: "0px",
  margin: "0px",

  color: active ? "#4a98d3" : "#fff",

  "&:hover": {
    color: "#4a98d3",
  },
}));

export const TopNav = (props) => {
  const { onNavOpen, navOpen, onStateSelectionOpen } = props;
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const location = useLocation();

  const isSelectStateModalVisible = useSelector(
    selectIsSelectStateModalVisible
  );

  return (
    <Box
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: (theme) => theme.palette.neutral["1000"],
        // background:"green",
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        // px: 1,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          p: 0,
        }}
      >
        <Box component="header">
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{
              minHeight: TOP_NAV_HEIGHT,
              px: {
                xs: 2,
                sm: 0,
              },
              position: "relative",
            }}
          >
            <Link to="/">
              <LogoStyles
                src="/assets/logos/logo-beep-light.png"
                alt="thebeep"
                height={43}
              />
            </Link>
            {/* <MenuDropdown /> */}
            {/* <Stack alignItems="center" direction="row" spacing={2}>
            <button
              style={{
                border: "none",
                outline: "none",
                background: "transparent",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={onStateSelectionOpen}
            >
              <LocationIcon height={25} />
              {matches && state && (
                <Typography
                  variant="p"
                  sx={{
                    ml: "14px",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FBFCFF",
                  }}
                >
                  {stateMap[state]}
                </Typography>
              )}
            </button>
          </Stack> */}
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              {!navOpen? <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <HamburgerIcon />
                </SvgIcon>
              </IconButton>:
              
              <IconButton onClick={onNavOpen}>
              <img
                src="/assets/icons/close.svg"
                alt="close"
                style={{ width: "16px", height: "16px",  }}
              />
            </IconButton>}
            </Stack>
            <Stack
              direction="row"
              sx={{
                display: { xs: "none", md: "flex", gap: "24px" },
              }}
            >
              <LinkStyles
                to="/price-tracker/state"
                active={location.pathname.includes("price-tracker")}
              >
                Price Tracker
              </LinkStyles>
              <LinkStyles
                to="/offer-tracker/state"
                active={location.pathname.includes("offer-tracker")}
              >
                Offer & Deals
              </LinkStyles>
              <MenuDropdown name="Resources" options={resourcesoptions} />
              <MenuDropdown name="Company" options={companyoptions} />
              {/* <LinkStyles to="/media" active={location.pathname === "/media"}>
              Media
            </LinkStyles>
            <LinkStyles to="/aboutus" active={location.pathname === "/aboutus"}>
              About
            </LinkStyles>
            <LinkStyles to="/contact" active={location.pathname === "/contact"}>
              Contact
            </LinkStyles> */}
              <IconButtonStyles
                // style={{
                //   border: "none",
                //   outline: "none",
                //   background: "transparent",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "center",
                //   cursor: "pointer",
                //   padding: "0px",
                //   margin: "0px",
                //   color: isSelectStateModalVisible ? "#4A98D3" : "#fff",
                //   // style={{ color: "#4a98d3" }}
                // }}
                active={isSelectStateModalVisible}
                onClick={onStateSelectionOpen}
              >
                <LocationIcon height={18} />
              </IconButtonStyles>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
  onStateSelectionOpen: PropTypes.func,
};
