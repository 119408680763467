import ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { router } from "@/App";
import { persistor, store } from "@/app/store";
import { createTheme } from "@/theme";
import { Provider } from "react-redux";

import "@/index.css";
import { Toaster } from "react-hot-toast";

import React, { useEffect } from "react";

import { HelmetProvider } from "react-helmet-async";

// google analytics
import ReactGA from "react-ga4";
ReactGA.initialize("G-X8ZKYMEVY1");

const Favicon = () => {
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const favicon = document.getElementById("favicon");

    const setFavicon = () => {
      if (darkModeMediaQuery.matches) {
        // Set dark mode favicon
        favicon.href = "/thebeep_favicon_on-black.png";
      } else {
        // Set light mode favicon
        favicon.href = "/thebeep_favicon_on-white.png";
      }
    };

    // Initial setup
    setFavicon();

    // Update favicon when dark mode preference changes
    darkModeMediaQuery.addEventListener("change", setFavicon);

    // Cleanup on component unmount
    return () => {
      darkModeMediaQuery.removeEventListener("change", setFavicon);
    };
  }, []);

  return null; // This component doesn't render anything in the UI
};

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{ duration: 3000 }}
          />
          <Favicon />
          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </>
);
