import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const useAnalyticsTitle = (title) => {
  const [documentTitle, setDocumentTitle] = useState(title);

  useEffect(() => {
    // google analytics
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: documentTitle,
    });
  }, [documentTitle]);

  return [documentTitle, setDocumentTitle];
};

export { useAnalyticsTitle };
