import { Listbox } from "@headlessui/react";
import { useField } from "formik";
import { colors } from "@/constants/colors";
import { styled } from "@mui/material/styles";

import { Scrollbar } from "@/components/scrollbar";
import { ChevronDown } from "lucide-react";

import "./FormSelect.css";
import { Fragment } from "react";

const ErrorText = styled("span")(({ theme }) => ({
  width: "100%",
  fontSize: "13px",
  fontStyle: "italic",
  fontWeight: 300,
  lineHeight: "160%",
  letterSpacing: "0.065px",
  color: "#EC4D3F",
}));

const FormSelect = ({ name, label, options, disabled = false, style = {} }) => {
  const [field, meta, helpers] = useField(name);

  const handleSelect = (value) => {
    helpers.setValue(value, true);
  };

  return (
    <div
      style={{
        ...style,
      }}
    >
      <Listbox
        className={`select-container-1 ${disabled && "disabled"}`}
        style={{}}
        as="div"
        onChange={(e) => handleSelect(e)}
        disabled={disabled}
      >
        <Listbox.Button as="div" className="select-container-1-button">
          <p>{label}</p>
          <span>
            <p>{field.value}</p>
            <ChevronDown className="dropdown" />
          </span>
        </Listbox.Button>
        <Scrollbar className="select-scrollbar">
          <Listbox.Options className="select-container-1-list">
            {options?.map((option, index) => (
              <Listbox.Option key={index} value={option} as={Fragment}>
                {() => <li className="select-container-1-item">{option}</li>}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Scrollbar>
      </Listbox>

      {meta.touched && meta.error ? <ErrorText>{meta.error}</ErrorText> : null}
    </div>
  );
};

export default FormSelect;
