// import { apiSlice } from "../../app/api/apiSlice";

import { apiSlice } from "../../api/apiSlice";

export const contact = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createContactRequest: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/contact-us`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useCreateContactRequestMutation } = contact;
