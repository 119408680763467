import React from "react";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Facebook } from "@/assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "@/assets/icons/instagram.svg";

import { ReactComponent as TikTok } from "@/assets/icons/tiktok.svg";

import { ReactComponent as LinkedIn } from "@/assets/icons/linkedin.svg";

import { Link } from "react-router-dom";

const CopyrightText = styled("p")(({ theme }) => ({
  textAlign: "center",
  margin: 0,
  padding: 0,
}));

const FooterLink = styled(Link)(() => ({
  // fontSize: "13px",
  fontWeight: 400,
  lineHeight: "21px",
  letterSpacing: "0.005em",
  color: "#fff",
}));

const Heading = styled("h5")(({ theme }) => ({
  margin: 0,
  padding: 0,
  textAlign: "center",
  marginBottom: "1rem",

  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "26px",
  letterSpacing: "0.005em",
}));

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) => theme.palette.neutral["1000"],
        color: "#fff",
        p: "24px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Heading>Connect with us</Heading>
      <Stack direction="row" gap="65px" mb="40px">
        <a
          target="_blank"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          rel="noreferrer"
          href="https://www.facebook.com/thebeepau"
        >
          <Facebook
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          href="https://www.instagram.com/thebeepau"
        >
          <Instagram
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          href="https://www.tiktok.com/@thebeepau"
        >
          <TikTok
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          href="https://www.linkedin.com/company/thebeep"
        >
          <LinkedIn
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </a>
      </Stack>

      <Stack direction="row" gap="32px" mb="40px">
        <FooterLink to="/privacy/state">Privacy Policy</FooterLink>
        <FooterLink to="/terms/state">Terms of Use</FooterLink>
        <FooterLink to="/advertise/state">Advertise</FooterLink>
      </Stack>
      {/* 
      <Link to="/">
        <img
          style={{
            maxWidth: "50px",
          }}
          src="/assets/logos/logo-beep-light.png"
          alt="thebeep"
        />
      </Link> */}

      <CopyrightText>
        Copyright © {new Date().getFullYear()} | The Beep Australia Pty Ltd
      </CopyrightText>
    </Box>
  );
};

export default Footer;
