export function formatCurrency({ price, sign = false }) {
  if (typeof price !== "number") throw new Error("Price should be a number");

  const temp = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    signDisplay: sign ? "exceptZero" : "never",
  });

  return temp.format(price);
}

export function formatNumberWithSign(price) {
  if (price === undefined) return;

  if (typeof price !== "number") throw new Error("Price should be a number");

  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    signDisplay: "never",
  }).format(price);
}
