import { useCallback, useEffect, useState } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { TopNav } from "@/layouts/HomeLayout/top-nav";
import { SideNav } from "@/layouts/HomeLayout/side-nav";
import { SIDE_NAV_WIDTH } from "@/constants/navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsSelectStateModalVisible,
  toggleSelectedStateModal,
} from "@/app/features/userState/userStateSlice";
import Footer from "@/layouts/HomeLayout/footer";
import { StateSelectionModal } from "@/components/state-selection-modal";

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

const HomeLayout = () => {
  const outlet = useOutlet();

  const dispatch = useDispatch();

  let { pathname } = useLocation();
  const [openNav, setOpenNav] = useState(false);

  // const [openStateSelection, setOpenStateSelection] = useState();

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(
    () => {
      handlePathnameChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <>
      <TopNav
        navOpen={openNav}
        onNavOpen={() => setOpenNav(!openNav)}
        onStateSelectionOpen={() => {
          dispatch(toggleSelectedStateModal());
        }}
      />
      <SideNav onClose={() => setOpenNav(false)} open={openNav} />
      <LayoutRoot>
        <LayoutContainer>{outlet}</LayoutContainer>
      </LayoutRoot>
      <Footer />
      {/* {matches ? (
        <StateSelectionModal
          onClose={() => {
            dispatch(toggleSelectedStateModal());
          }}
          open={isSelectStateModalVisible}
        />
      ) : (
        <StateSelection
          onClose={() => {
            dispatch(toggleSelectedStateModal());
          }}
          open={isSelectStateModalVisible}
        />
      )} */}

      <StateSelectionModal />
    </>
  );
};

export default HomeLayout;
