import React from "react";
import { Box, IconButton, Modal as MUIModal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ open, onClose, children, style, hideCloseIcon = false }) => {
  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "20px",
    width: "90%",
    maxWidth: "800px",
    ...style,
  };

  return (
    <MUIModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>
        <Box sx={{ position: "relative" }}>
          {hideCloseIcon ? null : (
            <IconButton
              onClick={onClose}
              sx={{
                position: "fixed",
                top: "1rem",
                right: "1rem",
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            </IconButton>
          )}

          {children}
        </Box>
      </Box>
    </MUIModal>
  );
};

export default Modal;
