import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";
import { useParams } from "react-router-dom";
import AdCarousel from "@/components/ad-caraousel";
import { styled } from "@mui/material/styles";
import { AdvertiseWithUsIcon } from "@/assets/images/advertise";

const MainContainer = styled("main")(({ theme }) => ({
  width: "88%",
  maxWidth: "1200px",
  margin: "auto",
  marginTop: "20px",
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const TopHeaderSection = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  margin: "2rem 0px",
  [theme.breakpoints.down("md")]: {
    margin: "0px 0px 2rem 0px",
  },
}));

const Heading = styled("h3")(({ theme }) => ({
  textAlign: "center",
  fontSize: "25.2px",
  margin: "0px",
}));

const AdvertiseHeading = styled("h4")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  // fontWeight: 500,
  marginTop: "16px",
  lineHeight: "2rem",
  [theme.breakpoints.up("md")]: {
    marginTop: "20px",
  },
}));

const TopParagraph = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontSize: "17.2px",
  fontWeight: 400,
  margin: "0px",
  color: "#000",
  lineHeight: "2rem",
}));

const AdvertiseTextContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.3rem",
  margin: "2rem 0px",
}));

const AdvertiseOptionContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2rem",
  margin: "2rem 0px 1rem 0px",
}));

const AdvertiseOption = styled("div")(({ theme }) => ({
  display: "flex",
  width: "80% !important",
  justifyContent: "center",
  gap: "2rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    width: "100% !important",
    gap: "0px",
  },
}));

const ContactSection = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "2rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
  flex: "45%",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  flex: "55%",
}));

const AdvertiseImg = styled("img")(({ theme }) => ({
  width: "100% !important",
  height: "100%",
  objectFit: "contain",
}));

const AdvertisePage = () => {
  const { state: stateChoiceFromUrl } = useParams();

  useAnalyticsTitle(pageContent.advertise.title);

  return (
    <>
      <Helmet>
        <title>{pageContent.advertise.title}</title>
        {pageContent.advertise.description && (
          <meta
            name="description"
            content={pageContent.advertise.description}
          />
        )}
      </Helmet>

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      <MainContainer>
        <TopHeaderSection>
          <Heading>Advertise With TheBeep.com.au</Heading>
          <TopParagraph>
            Thank you for your interest in advertising with The Beep. We are the
            perfect platform to showcase your brand and connect with your ideal
            audience. Here's why advertising with us is the smart choice:
          </TopParagraph>
        </TopHeaderSection>
        <AdvertiseTextContainer>
          <TopParagraph>
            <b>Targeted Reach:</b> Our site attracts an audience of prospective
            new car buyers ensuring your message resonates with those who matter
            most to your business.
          </TopParagraph>
          <TopParagraph>
            <b>Value for Money:</b> Get the most out of your advertising budget.
            Our rates begin at a low $99 (ex GST) per week and provide
            significant coverage for your brand.
          </TopParagraph>
          <TopParagraph>
            <b>Authentic Engagement:</b> From stand-out banners to integrated
            content, our advertising options seamlessly blend with the user
            experience, fostering genuine engagement with your brand.
          </TopParagraph>
          <TopParagraph>
            <b>Supportive Partnership:</b> Exceptional support from those with
            automotive experience ensuring your advertising journey is smooth
            and stress-free.
          </TopParagraph>
        </AdvertiseTextContainer>
        <AdvertiseOptionContainer>
          <Heading>Advertising Options</Heading>
          <TopParagraph>
            You can secure the following integration options for your brand in
            your state and lock out the competition all for a low $99 (ex GST)
            per week.
          </TopParagraph>
          <AdvertiseOption>
            <TextContainer>
              <AdvertiseHeading>Display Banners</AdvertiseHeading>
              <TopParagraph>
                Your banner advert placement at the top & bottom of every page.
                These can be set to national or state specific visibility.
              </TopParagraph>
              <AdvertiseHeading>Brand Banners & Text</AdvertiseHeading>
              <TopParagraph>
                Own your brand on our website! Place your banner and custom text
                underneath a chosen Make so that whenever a user clicks that
                Make - your ad appears!
              </TopParagraph>

              <AdvertiseHeading>Exclusive Offer</AdvertiseHeading>
              <TopParagraph>
                List your exclusive offer in our Offers & Deals page.
              </TopParagraph>
            </TextContainer>
            <ImageContainer>
              <AdvertiseImg src={AdvertiseWithUsIcon} alt="Advertise With Us" />
            </ImageContainer>
          </AdvertiseOption>
          <ContactSection>
            <Heading>Contact</Heading>
            <TopParagraph>
              To discuss advertising with The Beep please contact{" "}
              <a
                href="mailto:hello@thebeep.com.au?subject=Advertising%20Enquiry"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#000" }}
              >
                hello@thebeep.com.au
              </a>
            </TopParagraph>
          </ContactSection>
        </AdvertiseOptionContainer>
      </MainContainer>

      {stateChoiceFromUrl && (
        <Box mt={"16px"} mb={"16px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </>
  );
};

export default AdvertisePage;
