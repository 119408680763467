import { apiSlice } from "../../api/apiSlice";

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    keepUnusedDataFor: 1,
    getOfferMake: builder.query({
      query: ({ state }) => `/api/v1/offer-tracker/make?stateCode=${state}`,
    }),
    getOffers: builder.query({
      query: ({ make, offer, search, state }) => {
        const currentDate = new Date();
        const dateStr = `${currentDate.getDate()}/${currentDate.getMonth() + 1
          }/${currentDate.getFullYear()}`;
        const offerCapitalized = offer.length > 1 ? offer[0].toUpperCase() + offer.slice(1) : offer;
        return `/api/v1/offer-tracker?make=${make}&offer=${offerCapitalized}&search=${search}&date=${dateStr}&stateCode=${state}`;
      },
    }),
  }),
});

export const { useGetOfferMakeQuery, useGetOffersQuery } = configApiSlice;
