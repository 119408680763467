import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useCarStore from "@/context/carStore";
import pageContent from "@/constants/pageContent";
import { DEFAULT__CHART_FILTER } from "@/constants/filters";
import { Helmet } from "react-helmet-async";
import AdCarousel from "@/components/ad-caraousel";
import { DEFAULT_STATE } from "@/constants/states";
import { useDispatch } from "react-redux";
import {
  showSelectedStateModal,
  toggleSelectedStateModal,
} from "@/app/features/userState/userStateSlice";

const Homepage = () => {
  useAnalyticsTitle(pageContent.home.title);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const outlet = useOutlet();
  const dispatch = useDispatch();

  const { state: stateChoiceFromUrl } = useParams();

  const { resetChart } = useCarStore((store) => store);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (!stateChoiceFromUrl) {
      const persistedStateChoice = localStorage.getItem("state");

      const urlParams = {
        make: ["All"],
        model: ["All"],
        grade: ["All"],
        state: persistedStateChoice ? persistedStateChoice : DEFAULT_STATE,
        timeline: DEFAULT__CHART_FILTER,
        filterType: "model",
      };

      const url = new URLSearchParams(urlParams);

      if (persistedStateChoice) {
        resetChart();

        navigate(
          `/price-tracker/state/${persistedStateChoice}?${url.toString()}`
        );
      }
      // else {
      //   navigate(`/price-tracker/state/${DEFAULT_STATE}?${url.toString()}`);
      // }
      else {
        dispatch(showSelectedStateModal());
      }
    }
  }, [location, searchParams, stateChoiceFromUrl, navigate]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        paddingBottom: "50px",
      }}
    >
      <Helmet>
        <title>{pageContent.home.title}</title>
        {pageContent.home.description && (
          <meta name="description" content={pageContent.home.description} />
        )}
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="the beep australia, the beep, car price tracker, thebeep, car price graph, the beep!, car price comparison, car prices, car valuations, car values, car price tracker, automotive prices, car prices graph, automotive pricing"
        />
      </Helmet>
      <Typography
        component="h1"
        textAlign="center"
        sx={{
          fontWeight: 400,
          fontSize: {
            xs: "16px",
            md: "16px",
          },
          lineHeight: "123.5%",
          mt: {
            xs: "16px",
            md: "40px",
          },
          mb: {
            xs: "16px",
            md: "24px",
          },

          "& b": {
            fontWeight: 600,
          },
        }}
      >
        Australia’s new car research companion with free access to the latest
        data from over 40 brands and 1,000 models. Compare specifications, track
        pricing and find the best deal for your next car purchase today.
      </Typography>

      {stateChoiceFromUrl && (
        <Box mb="16px">
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      {outlet}

      <Typography
        component="h1"
        textAlign="center"
        sx={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          mt: {
            xs: "16px",
            md: "20px",
          },
        }}
      >
        Vehicle pricing shown is for new cars only (base model excluding
        options). Unless otherwise indicated, vehicle pricing is based on the
        published estimated drive away price where available. (1) Fuel
        consumption shown is the combined cycle measurement. See our Terms of
        Use for further details.
      </Typography>

      {stateChoiceFromUrl && (
        <Box mt={"32px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </Container>
  );
};

export default Homepage;
