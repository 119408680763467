import { ChevronDown, RefreshCw } from "lucide-react";
import { Listbox } from "@headlessui/react";
import "./SelectDemo.css";
import { Scrollbar } from "@/components/scrollbar";

import Slider from "@mui/material/Slider";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce } from "@/hooks/use-debounce";
import { useCarLocation } from "@/hooks/use-car-location";

function valuetext(value) {
  if (value >= 1000) {
    let suffix = "K";
    let roundedNum = Math.round(value / 1000);
    return `$${roundedNum}${suffix}`;
  }
  return `$${value.toString()}`;
}

function PriceRangeDropdown({
  options,
  name,
  label,
  disabled = false,
  background,
  defaultOption,
  onChange,
  minimum,
  maximum,
  currentValue,
  isLoading = false,
  index,
}) {
  const [value, setValue] = useState(
    currentValue.split("-").map((e) => parseInt(e, 10))
  );

  const { updatePriceRange, handlePriceRangeChange } = useCarLocation();

  const theme = useTheme();

  const dValue = useDebounce(value, 1000);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    handlePriceRangeChange(index);
  };

  useEffect(() => {
    setValue(currentValue.split("-").map((e) => parseInt(e, 10)));
  }, [currentValue]);

  useEffect(() => {
    updatePriceRange(name, `${dValue[0]}-${dValue[1]}`, index);
  }, [dValue]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     // onChange(name, `${value[0]}-${value[1]}`);
  //     console.log("debounced");
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [value]);

  const isAllSelected =
    (parseInt(value[0], 10) === minimum &&
      parseInt(value[1], 10) === maximum) ||
    parseInt(value[0], 10) === 0;

  // if (isLoading) {
  //   return <Skeleton variant="rectangular" width={"100%"} height={"100%"} />;
  // }

  return (
    <Listbox
      className={`select-container ${disabled || isLoading ? "disabled" : ""}`}
      style={{
        "--clr-primary": background,
      }}
      as="div"
      value={value}
      // onChange={handleSelect}
      disabled={disabled || isLoading}
    >
      <Listbox.Button as="div" className="select-container-button">
        <p style={{ fontWeight: "700" }}>{label}</p>
        <span>
          {isLoading ? (
            <RefreshCw className="loader" />
          ) : (
            <>
              <p
                className="select-container-value"
                style={{ fontSize: "14px" }}
              >
                {isAllSelected
                  ? "All"
                  : `${valuetext(value[0])} - ${valuetext(value[1])}`}
              </p>
              <ChevronDown className="dropdown" />
            </>
          )}
        </span>
      </Listbox.Button>
      <Scrollbar
        className="select-scrollbar"
        style={{
          maxHeight: "300px",
        }}
      >
        <Listbox.Options
          className="select-container-list"
          style={{
            boxShadow: "none",
          }}
        >
          <Box
            sx={{
              height: "55px",
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              p: 1,
              pr: 2,
              // pb: 2,
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
              }}
            >
              <Slider
                getAriaLabel={() => "Temperature"}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
                min={minimum}
                max={maximum}
                step={1000}
                sx={{
                  position: "absolute",

                  color:
                    theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
                  "& .MuiSlider-track": {
                    border: "none",
                  },
                  "& .MuiSlider-thumb": {
                    width: 24,
                    height: 24,
                    backgroundColor: "#fff",
                    "&::before": {
                      // boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                    },
                    "&:hover, &.Mui-focusVisible, &.Mui-active": {
                      boxShadow: "none",
                    },
                  },
                  "& .MuiSlider-valueLabel": {
                    fontSize: 12,
                    fontWeight: "normal",
                    top: "50px",
                    backgroundColor: "unset",
                    color: theme.palette.text.primary,
                    "&::before": {
                      display: "none",
                    },
                    "& *": {
                      background: "transparent",
                      color: "#fff",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Listbox.Options>
      </Scrollbar>
    </Listbox>
  );
}

export default PriceRangeDropdown;
