import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import moment from "moment";

const OverviewContainer = styled("div")`
  background: #dbeaf6;
  border-radius: 4px;
  padding: 16px;
  height: max-content;
  margin-bottom: 1rem;
`;

const Heading = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 400,
  fontSize: "19px",
  lineHeight: "28px",
  margin: 0,
  padding: 0,
}));

const StyledGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "60px 5fr",
}));

const OverviewHeader = styled("div")`
  border-bottom: 1px solid #b7d6ed;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const Divider = styled("hr")`
  border: none;
  outline: none;
  border-bottom: 1px solid #b7d6ed;
`;

const Title = styled("p")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "160%",
  color: "#666666",
  letterSpacing: "0.005em",
  margin: 0,
  padding: 0,
}));

const Value = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "160%",
  margin: 0,
  padding: 0,
  letterSpacing: "0.005em",
}));

const LinkValue = styled("a")(({ theme }) => ({
  color: "#0d0d0d",
  textDecoration: "none",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "120%",
  margin: 0,
  padding: 0,
  letterSpacing: "0.005em",
  display: "flex",
  alignItems: "center",
}));

const ShowOnMobile = styled("span")(({ theme }) => ({
  display: "inline-block",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const LineBreakDesktop = styled("br")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const OfferType = styled("p")(({ type }) => ({
  color: type === "new" ? "#2fbc74" : "#11b4f0",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "160%",
  margin: 0,
  padding: "0px 8px",
  border: type === "new" ? "2px solid #2fbc74" : "2px solid #11b4f0",
  letterSpacing: "0.005em",
  textTransform: "uppercase",
}));

//

const OfferLisiting = ({ isLoading, error, items }) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" width={"100%"} height={300} />;
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      </Container>
    );
  }

  return items.length > 0 ? (
    items.map(
      (
        {
          make,
          linkAddress,
          linkText,
          modelsText,
          offer,
          offerText,
          offerTitle,
          fromDate,
          toDate,
          offerType,
          id,
        },
        index
      ) => (
        <div className="offer-item-container" key={index}>
          <OverviewContainer>
            <OverviewHeader
              style={{
                display: "inline-flex",
                columnGap: "10px",
                alignItems: "center",
              }}
            >
              {offerType && <OfferType type={offerType}>{offerType}</OfferType>}
              <Heading>{`${make} ${offerTitle}`}</Heading>
            </OverviewHeader>

            <div>
              <StyledGrid>
                <Title>Offer</Title>
                <Value sx={{ fontWeight: 400 }}>{offerText}</Value>
              </StyledGrid>

              <StyledGrid>
                <Title>Models</Title>
                <Value sx={{ fontWeight: 400 }}>{modelsText}</Value>
              </StyledGrid>

              <StyledGrid>
                <Title>Term</Title>
                <Value sx={{ fontWeight: 400 }}>
                  {moment(fromDate).format("Do MMMM YYYY")} to{" "}
                  {toDate
                    ? moment(toDate).format("Do MMMM YYYY")
                    : "Not Specified"}
                  <ShowOnMobile>.</ShowOnMobile> <LineBreakDesktop />
                  Conditions and exclusions may apply. See manufacturer’s
                  website for more details.
                </Value>
              </StyledGrid>
            </div>

            {linkAddress && linkText ? (
              <Box>
                <Divider />
                {/* <Title sx={{ display: "inline", mr: "0.3rem" }}>Ad</Title> */}
                <Title
                  sx={{
                    fontSize: "0.8rem",
                    textTransform: "uppercase",
                  }}
                >
                  advertisement
                </Title>
                <LinkValue
                  href={linkAddress}
                  target="_blank"
                  sx={{ display: "inline" }}
                >
                  {linkText}
                </LinkValue>
              </Box>
            ) : null}
          </OverviewContainer>
        </div>
      )
    )
  ) : (
    <Heading
      sx={{
        textAlign: "center",
        fontWeight: 600,
      }}
    >
      No offers found
    </Heading>
  );
};

export default OfferLisiting;
