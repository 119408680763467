import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Modal from "@/components/modal";
import { styled } from "@mui/material/styles";

import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import TwitterIcon from "@mui/icons-material/Twitter";
import IosShareIcon from "@mui/icons-material/IosShare";
import useCopyToClipboard from "@/layouts/HomeLayout/use-copy-to-clipboard";
import useCarStore from "@/context/carStore";

function getShareTextCar({ make, model, grade }) {
  if (make === "All" && model === "All" && grade === "All") return "All";
  else if (make !== "All" && model === "All" && grade === "All") return make;
  else if (make !== "All" && model !== "All" && grade === "All")
    return `${make} ${model}`;
  else if (make !== "All" && model !== "All" && grade !== "All")
    return `${make} ${model} ${grade}`;
}

const useShareLink = ({ type }) => {
  const [url, setUrl] = useState("");

  const isTablet = useMediaQuery("(min-width:600px)");

  const { chartData } = useCarStore();

  useEffect(() => {
    const browserUrl = window.location.href;

    const carValues = chartData
      .map((car) =>
        getShareTextCar({
          make: car.make,
          model: car.model,
          grade: car.grade,
        })
      )
      .join(", ");

    const subject = `The Beep - ${carValues}`;

    const shareBodyText = `${subject} %0a ${browserUrl}`;

    if (type === "facebook") {
      setUrl(`https://www.facebook.com/sharer/sharer.php?u=${browserUrl}`);
    } else if (type === "twitter") {
      const twitterShareText = `${subject} %0a ${encodeURIComponent(
        browserUrl
      )}`;

      setUrl(`https://twitter.com/intent/tweet?&text=${twitterShareText}`);
    } else if (type === "whatsapp") {
      const whatsappShareText = `${subject} %0a ${encodeURIComponent(
        browserUrl
      )}`;

      if (isTablet)
        setUrl(`https://web.whatsapp.com/send?text=${whatsappShareText}`);
      else {
        setUrl(`https://api.whatsapp.com/send?text=${whatsappShareText}`);
      }
    } else if (type === "mail") {
      const encodedUrl = encodeURIComponent(browserUrl);
      const body = `${subject}%0D%0A${encodedUrl}`;

      setUrl(`mailto:?subject=${subject}&body=${body}`);
    }
  }, [type, isTablet, chartData]);

  return { url };
};

const StyledFacebookIcon = styled(FacebookIcon)(({ theme, disabled }) => ({
  height: "40px",
  width: "40px",
  color: "#000",
  [theme.breakpoints.down("md")]: {
    height: "30px",
    width: "30px",
  },
}));

const StyledIosShareIcon = styled(IosShareIcon)(({ theme, disabled }) => ({
  height: "20px",
  width: "20px",
  color: "#000",
  [theme.breakpoints.down("md")]: {
    height: "30px",
    width: "30px",
  },
}));

const StyledWhatsAppIcon = styled(WhatsAppIcon)(({ theme, disabled }) => ({
  height: "40px",
  width: "40px",
  color: "#000",
  [theme.breakpoints.down("md")]: {
    height: "30px",
    width: "30px",
  },
}));
const StyledMailIcon = styled(MailIcon)(({ theme, disabled }) => ({
  height: "40px",
  width: "40px",
  color: "#000",
  [theme.breakpoints.down("md")]: {
    height: "30px",
    width: "30px",
  },
}));

const StyledTwitterIcon = styled(TwitterIcon)(({ theme, disabled }) => ({
  height: "40px",
  width: "40px",
  color: "#000",
  [theme.breakpoints.down("md")]: {
    height: "30px",
    width: "30px",
  },
}));

const StyledShareItemLink = styled("a")(({ theme }) => ({
  color: "#000",
  display: "block",
  textAlign: "center",
  textDecoration: "none",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
}));

const ShareItemName = styled("p")(({ theme }) => ({
  color: "#000",
  display: "block",
  textAlign: "center",
  textDecoration: "none",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  margin: 0,
  padding: 0,

  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    lineHeight: "18px",
  },
}));

function ShareItem({ name, link, icon, type }) {
  const { url } = useShareLink({ type });

  return (
    <Grid item xs={3}>
      <StyledShareItemLink href={url} target="_blank">
        <Stack alignItems="center" gap={1}>
          {icon}
          <ShareItemName>{name}</ShareItemName>
        </Stack>
      </StyledShareItemLink>
    </Grid>
  );
}

function ShareItemFooter() {
  const { copyToClipboard } = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);

  const isTablet = useMediaQuery("(min-width:600px)");

  const handleCopyLink = () => {
    copyToClipboard(window.location.href);
    setIsCopied(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#dbeaf6",
        padding: "10px 24px",
        borderRadius: "0 0 20px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      {isTablet && <StyledIosShareIcon />}
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "14px", md: "16px" },
        }}
      >
        {window.location.href.substring(0, isTablet ? 40 : 25) + "...."}
      </Typography>
      <Button
        disabled={isCopied}
        onClick={handleCopyLink}
        sx={{
          color: "#000",
          fontWeight: 400,
          fontSize: { xs: "14px", md: "16px" },
          lineHeight: "24px",
          width: "110px",
        }}
      >
        {isCopied ? "Copied" : "Copy Link"}
      </Button>
    </Box>
  );
}

const ShareModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ maxWidth: "500px", p: 0 }}
    >
      <Box p={3}>
        <Typography variant="h6">Share</Typography>

        <Box mt={5}>
          <Grid container>
            <ShareItem
              name="Facebook"
              icon={<StyledFacebookIcon />}
              type="facebook"
            />
            <ShareItem
              name="Twitter"
              icon={<StyledTwitterIcon />}
              type="twitter"
            />
            <ShareItem
              name="Whatsapp"
              icon={<StyledWhatsAppIcon />}
              type="whatsapp"
            />
            <ShareItem name="Mail" icon={<StyledMailIcon />} type="mail" />
          </Grid>
        </Box>
      </Box>

      <ShareItemFooter />
    </Modal>
  );
};

export default ShareModal;
