import { Fragment } from "react";
import { ChevronDown } from "lucide-react";
import { Listbox } from "@headlessui/react";
import { Scrollbar } from "@/components/scrollbar";

function StateSelectionDropdown({
  options,
  name,
  label,
  disabled = false,
  background,
  defaultOption,
  onChange,
  value,
}) {
  function handleSelect(v) {
    if (onChange) onChange(name, v);
  }

  return (
    <Listbox
      className={`select-container ${disabled ? "disabled" : ""}`}
      style={{
        "--clr-primary": background,
      }}
      as="div"
      value={value}
      onChange={handleSelect}
      disabled={disabled}
    >
      <Listbox.Button as="div" className="select-container-button">
        <p style={{ fontWeight: "700" }}>{label}</p>
        <span>
          <p className="select-container-value">{value}</p>
          <ChevronDown className="dropdown" />
        </span>
      </Listbox.Button>
      <Scrollbar
        className="select-scrollbar"
        style={{
          maxHeight: "136px",
        }}
      >
        <Listbox.Options className="select-container-list">
          {defaultOption.map((option) => (
            <Listbox.Option value={option} key={option} as={Fragment}>
              {() => <li className="select-container-item">{option}</li>}
            </Listbox.Option>
          ))}
          {options?.map((option, index) => (
            <Listbox.Option key={index} value={option} as={Fragment}>
              {() => <li className="select-container-item">{option}</li>}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Scrollbar>
    </Listbox>
  );
}

export default StateSelectionDropdown;
