// import { apiSlice } from "../../app/api/apiSlice";

import { apiSlice } from "../../api/apiSlice";

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAbout: builder.query({
      query: () => "/api/v1/config/about",
    }),
    getPrivacy: builder.query({
      query: () => "/api/v1/config/privacy",
    }),

    getTerms: builder.query({
      query: () => "/api/v1/config/terms",
    }),

    getAdvertise: builder.query({
      query: () => "/api/v1/config/advertise",
    }),

    getPricingWorks: builder.query({
      query: () => "/api/v1/config/pricing-works",
    }),

    getMedia: builder.query({
      query: () => "/api/v1/config/media",
    }),
  }),
});

export const {
  useGetAboutQuery,
  useGetPrivacyQuery,
  useGetTermsQuery,
  useGetAdvertiseQuery,
  useGetPricingWorksQuery,
  useGetMediaQuery,
} = configApiSlice;
