import { styled } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";
import Chart from "@/pages/Homepage/components/Chart";
import useCarStore from "@/context/carStore";
import { useCarLocation } from "@/hooks/use-car-location";
import { CHART_FILTERS_LIST } from "@/constants/filters";

const ChartContainer = styled("div")`
  padding-top: 20px;
  padding-bottom: 30px;
  margin-top: 20px;
`;

const TimeLineButton = styled(Button)(({ ischosen, theme }) => ({
  background: ischosen === "yes" ? "#DBEAF6" : "#f2f2f2",
  border: `1px solid ${ischosen === "yes" ? "#4A98D3" : "#b3b3b3"}`,
  borderRadius: "4px",
  fontSize: "16px",
  lineHeight: "160%",
  color: "#0d0d0d",
  padding: "0 23px",
  fontWeight: 400,
  flex: 1,

  "&:hover": {
    background: "inherit",
  },
}));

const LineChart = () => {
  const { chartData } = useCarStore();
  const { values, updateSearchParams } = useCarLocation();
  const { timeline } = values;

  return (
    <div className="chart-container">
      <Stack direction="row" gap={2}>
        {CHART_FILTERS_LIST.map((item, index) => (
          <TimeLineButton
            key={index}
            ischosen={item === timeline ? "yes" : "no"}
            onClick={() => {
              updateSearchParams("timeline", item);
            }}
          >
            {item}
          </TimeLineButton>
        ))}
      </Stack>

      <ChartContainer>
        <Chart timeSelector={timeline} chartData={chartData} />
      </ChartContainer>
    </div>
  );
};
export default LineChart;
