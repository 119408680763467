import { DEFAULT__CHART_FILTER } from "@/constants/filters";
import { SearchByModel } from "@/sections/SelectCarForm/search-by-model";
import { styled } from "@mui/material";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { SearchByCriteria } from "@/sections/SelectCarForm/search-by-criteria/search-by-criteria";
import useCarStore from "@/context/carStore";
import { useCarLocation } from "@/hooks/use-car-location";

const SearchByContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginLeft: "20px",
  marginTop: "32px",
  gap: "16px",
  marginBottom: "16px",
  [theme.breakpoints.down("md")]: {
    // flexDirection: "column",
    // gap: "20px",
    marginTop: "24px",
  },

  "::before": {
    content: '""',
    flex: 1,
    height: "1px",
    backgroundColor: "#B3B3B3",
    // paddingLeft: "10px",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  "::after": {
    content: '""',
    // width: "100%",
    flex: 1,
    height: "1px",
    backgroundColor: "#B3B3B3",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const SearchByButton = styled("button")(({ theme, isActive }) => ({
  fontSize: "16px",
  lineHeight: "24px",
  border: "none",
  outline: "none",
  background: "transparent",
  color: isActive ? "#B3B3B3" : "#0D0D0D",
  fontWeight: 400,
  cursor: "pointer",
  padding: "0px 10px",

  "&:hover": {
    color: "#4a98d3",
  },

  "&:active": {
    color: isActive ? "#B3B3B3" : "#0D0D0D",
  },

  [theme.breakpoints.down("sm")]: {
    "&:hover": {
      color: isActive ? "#B3B3B3" : "#0D0D0D",
    },
  },
}));

const SelectCarForm = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { resetChart } = useCarStore((store) => store);

  const {
    values: { filterType },
  } = useCarLocation();

  // console.log(searchParams.get("filterType"));
  // const filterType = searchParams.get("filterType");
  const { state: stateChoiceFromUrl } = useParams();

  const handleSearchByClick = (type) => {
    if (type === "criteria") {
      setSearchParams({
        make: ["All"],
        model: ["All"],
        grade: ["All"],
        state: stateChoiceFromUrl,
        timeline: DEFAULT__CHART_FILTER,
        filterType: type,
        bodyStyle: ["All"],
        priceRange: ["All"],
        fuelType: ["All"],
      });
    } else {
      setSearchParams({
        make: ["All"],
        model: ["All"],
        grade: ["All"],
        state: stateChoiceFromUrl,
        timeline: DEFAULT__CHART_FILTER,
        filterType: type,
      });
    }

    resetChart();
  };

  return (
    <div>
      <SearchByContainer>
        <SearchByButton
          isActive={filterType === "criteria"}
          onClick={() => handleSearchByClick("model")}
        >
          Search by Model
        </SearchByButton>
        <span
          style={{
            color: "#000",
          }}
        >
          |
        </span>
        <SearchByButton
          isActive={filterType === "model"}
          onClick={() => handleSearchByClick("criteria")}
        >
          Search by Criteria
        </SearchByButton>
      </SearchByContainer>

      {filterType === "model" ? (
        <SearchByModel />
      ) : (
        <>
          <SearchByCriteria />
        </>
      )}
    </div>
  );
};

export default SelectCarForm;
