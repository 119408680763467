import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import { MoveDown } from "lucide-react";
import { useCarLocation } from "@/hooks/use-car-location";
import useCarStore from "@/context/carStore";
import { formatCurrency, formatNumberWithSign } from "@/helpers/utils";
import {
  useUpdateSponsoredAnalyticsMutation,
  useUpdateSponsoredAnalyticsViewsMutation,
} from "@/app/features/analytics/analyticsApiSlice";
import { useParams } from "react-router-dom";
import ShareCarButton from "@/pages/Homepage/components/ShareCarButton";
import { IMAGE_CDN_LINK } from "@/constants/links";

function getPercentageColor(priceChange) {
  if (priceChange < 0) return "#44BA4E";
  else if (priceChange > 0) return "#EC4D3F";
  else return "#666666";
}

const OverviewContainer = styled("div")(({ theme, hasImage }) => ({
  background: "#dbeaf6",
  borderRadius: "4px",
  padding: "16px",
  paddingTop: hasImage ? "50px" : "16px",
  height: "max-content",
  position: "relative",
}));

const OverviewContainerImage = styled("img")`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  /* width: 200px; */
  object-fit: contain;
`;

const Heading = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 400,
  fontSize: "19px",
  lineHeight: "28px",
  margin: 0,
  padding: 0,
  textAlign: "center",

  //   [theme.breakpoints.down("md")]: {
  //     width: "65%",
  //     alignSelf: "flex-start",
  //     position: "sticky",
  //     top: "100px",
  //   },
}));

const Subheading = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 300,
  fontSize: "16px",
  lineHeight: "24px",
  margin: 0,
  padding: 0,
  textAlign: "center",
}));

const OverviewHeader = styled("div")`
  border-bottom: 1px solid #b7d6ed;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const Divider = styled("hr")`
  border: none;
  outline: none;
  border-bottom: 1px solid #b7d6ed;
`;

const Title = styled("p")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "160%",
  color: "#666666",
  letterSpacing: "0.005em",
  margin: 0,
  padding: 0,
}));

const MoveDownIcon = styled(MoveDown)(({ theme, pricechange }) => ({
  width: "13px",
  height: "auto",
  strokeWidth: "3.5px",
  transform: pricechange < 0 ? "initial" : "rotate(180deg)",
}));

const Value = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "160%",
  margin: 0,
  padding: 0,
  letterSpacing: "0.005em",
  display: "flex",
  alignItems: "center",
}));

const LinkValue = styled("a")(({ theme }) => ({
  color: "#0d0d0d",
  textDecoration: "none",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "120%",
  margin: 0,
  padding: 0,
  letterSpacing: "0.005em",
  display: "flex",
  alignItems: "center",
}));

const PercentageChange = styled("p")(({ pricechange }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "160%",
  letterSpacing: "0.005em",
  color: getPercentageColor(pricechange),
  margin: 0,
  padding: 0,

  display: "flex",
  alignItems: "center",
}));

const OverviewBox = () => {
  const { chartData } = useCarStore();

  const { values } = useCarLocation();
  const { make, model, grade } = values;

  return (
    <Box className="overview-container">
      <Stack gap={5} mt={2}>
        {chartData.map((car, index) => (
          <OverviewContainer key={index} hasImage={car?.carImage?.found}>
            {car?.carImage?.found && (
              <OverviewContainerImage
                src={`${IMAGE_CDN_LINK}/${car?.carImage?.url}`}
                alt="car"
              />
            )}

            <OverviewHeader>
              <Heading>All</Heading>
              <Subheading>
                {car?.carAverageMinAndMax?.engine &&
                  car?.carAverageMinAndMax?.engine}
              </Subheading>
            </OverviewHeader>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Title>Current</Title>
                <Value>
                  {formatCurrency({
                    price: car?.carAverageMinAndMax?.current || 0,
                  })}
                </Value>
              </Stack>

              <Stack direction="row" alignItems="center" gap={1}>
                <Title>High</Title>
                <Value>
                  {formatCurrency({
                    price: car?.carAverageMinAndMax?.maxPrice || 0,
                  })}
                </Value>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Title>Avg</Title>
                <Value>
                  {formatCurrency({
                    price: car?.carAverageMinAndMax?.averagePrice || 0,
                  })}
                </Value>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <Title>Low</Title>
                <Value>
                  {formatCurrency({
                    price: car?.carAverageMinAndMax?.minPrice || 0,
                  })}
                </Value>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Title>Change</Title>
                <Value>
                  {formatCurrency({
                    price: car?.carAverageMinAndMax?.priceChange || 0,
                    sign: true,
                  })}
                </Value>
                <PercentageChange
                  pricechange={car?.carAverageMinAndMax?.priceChange}
                >
                  (
                  {car?.carAverageMinAndMax?.priceChange !== 0 && (
                    <MoveDownIcon
                      pricechange={car?.carAverageMinAndMax?.priceChange}
                    />
                  )}
                  {formatNumberWithSign(
                    car?.carAverageMinAndMax?.priceChangePercentage
                  )}
                  %)
                </PercentageChange>
              </Stack>
            </Stack>

            {car?.carAverageMinAndMax?.sponsoredDetails?.linkText ? (
              <OverviewBoxAdSection car={car} />
            ) : null}
          </OverviewContainer>
        ))}
      </Stack>
      <ShareCarButton />
    </Box>
  );
};

const OverviewBoxAdSection = ({ car }) => {
  const { state } = useParams();

  // state to track currently tracked ad view and make sure that double track is not happening
  const [currentlyTrackedAdView, setCurrentlyTrackedAdView] = useState("");

  const [updateSponsoredAnalytics] = useUpdateSponsoredAnalyticsMutation();

  const [updateSponsoredAnalyticsViews] =
    useUpdateSponsoredAnalyticsViewsMutation();

  const handleAdClickAnalytics = ({ link, make, model, grade }) => {
    const eventRef = `${make}${model !== "All" ? "|" + model : ""}${
      grade !== "All" ? "|" + grade : ""
    }`;
    updateSponsoredAnalytics({
      eventType: "sponsorDealerClick",
      eventRef: eventRef,
      eventValue: link,
      state,
    });
  };

  useEffect(() => {
    const sponsoredData = Object.assign(
      car?.carAverageMinAndMax?.sponsoredDetails ?? {}
    );

    // const tempCar = Object.assign(car);

    const eventRef = `${car?.make}${
      car?.model !== "All" ? "|" + car?.model : ""
    }${car?.grade !== "All" ? "|" + car?.grade : ""}`;

    if (
      car?.make !== "All" &&
      // car?.model !== "All" &&
      // car?.grade !== "All" &&
      Object.keys(sponsoredData).length > 0 &&
      currentlyTrackedAdView !== eventRef
    ) {
      setCurrentlyTrackedAdView(eventRef);

      updateSponsoredAnalyticsViews({
        eventType: "sponsorDealerView",
        eventRef: eventRef,
        eventValue: sponsoredData.linkText,
        state,
      });
    }
  }, [car, state]);

  return (
    <Box>
      <Divider />

      <Title
        sx={{
          fontSize: "0.8rem",
          textTransform: "uppercase",
        }}
      >
        advertisement
      </Title>
      <LinkValue
        href={car?.carAverageMinAndMax?.sponsoredDetails.linkAddress}
        target="_blank"
        sx={{ display: "inline" }}
        onClick={() => {
          handleAdClickAnalytics({
            link: car?.carAverageMinAndMax?.sponsoredDetails.linkText,
            make: car.make,
            model: car.model,
            grade: car.grade,
          });
        }}
      >
        {car?.carAverageMinAndMax?.sponsoredDetails.linkText}
      </LinkValue>
    </Box>
  );
};

export default OverviewBox;
