import { Fragment, useEffect, useState } from "react";
import {
  useGetCarBodyStyleQuery,
  useGetCarChartCriteriaQuery,
  useGetCarChartQuery,
  useGetCarFuelTypeQuery,
  useGetCarGradeQuery,
  useGetCarMakeQuery,
  useGetCarModelQuery,
  useGetCarPriceRangeQuery,
} from "@/app/features/car/carApiSlice";
import { Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SelectDemo from "@/components/Dropdown/SelectDemo";

import { colors } from "@/constants/colors";
import useCarStore from "@/context/carStore";
import { MinusCircleIcon } from "lucide-react";
import { getTooltipValue } from "@/pages/Homepage/components/chartOptions";
import { useCarLocation } from "@/hooks/use-car-location";
import { useUpdatePriceAnalyticsMutation } from "@/app/features/analytics/analyticsApiSlice";
import PriceRangeDropdown from "@/components/Dropdown/PriceRangeDropdown";
import { useSearchParams } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useDebounce } from "@/hooks/use-debounce";

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },

  "& > div": {
    flex: 1,
  },
}));

const SelectCarDetailsCriteria = ({
  make: Pmake,
  model: Pmodel,
  grade: Pgrade,
  state: Pstate,
  timeline: Ptimeline,
  index,
  bodyStyle: PbodyStyle,
  priceRange,
  fuelType: PfuelType,
}) => {
  const { updateSearchParams, handleCarDeletion } = useCarLocation();
  const PpriceRange = useDebounce(priceRange, 1000);
  const make = useDebounce(Pmake, 1000);
  const model = useDebounce(Pmodel, 1000);
  const grade = useDebounce(Pgrade, 1000);
  const state = useDebounce(Pstate, 1000);
  const timeline = useDebounce(Ptimeline, 1000);
  const bodyStyle = useDebounce(PbodyStyle, 1000);
  const fuelType = useDebounce(PfuelType, 1000);

  const { updatePriceRange } = useCarLocation();

  const { setChartData: updateChartData, removeCarFromChart } = useCarStore(
    (store) => store
  );

  const priceRangeHigh = PpriceRange === "All" ? 0 : PpriceRange.split("-")[1];

  const priceRangeLow = PpriceRange === "All" ? 0 : PpriceRange.split("-")[0];

  const [priceRangeValue, setPriceRangeValue] = useState({
    maximum: 0,
    minimum: 0,
  });

  const [updatePriceAnalytics] = useUpdatePriceAnalyticsMutation();

  const onChange = (name, value) => {
    updateSearchParams(name, value, index);

    if (value !== "All") {
      let eventRef = "";
      if (name === "make") {
        eventRef = `${value}`;
      } else if (name === "model") {
        eventRef = `${make}|${value}`;
      } else if (name === "grade") {
        eventRef = `${make}|${model}|${value}`;
      }

      // updatePriceAnalytics({
      //   eventType: name,
      //   eventValue: value,
      //   state,
      //   eventRef: eventRef,
      //   sponsored: name === "grade",
      // });
    }
  };

  const {
    data: carMake,
    isLoading,
    isError,
    isFetching: carMakeFetching,
  } = useGetCarMakeQuery(
    {
      stateCode: state,
      bodyStyle: bodyStyle,
      fuelType: fuelType,
      high: priceRangeHigh,
      low: priceRangeLow,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: carModel,
    isError: carModelError,
    isLoading: carModelLoading,
    isFetching: carModelFetching,
  } = useGetCarModelQuery(
    {
      stateCode: state,
      make: make,
      bodyStyle: bodyStyle,
      fuelType: fuelType,
      high: priceRangeHigh,
      low: priceRangeLow,
    },
    {
      skip: make === "All",
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: carGrade,
    isError: carGradeError,
    isLoading: carGradeLoading,
    isFetching: carGradeFetching,
  } = useGetCarGradeQuery(
    {
      stateCode: state,
      make: make,
      model: model,
      bodyStyle: bodyStyle,
      fuelType: fuelType,
      high: priceRangeHigh,
      low: priceRangeLow,
    },
    {
      skip: model === "All",
      refetchOnMountOrArgChange: true,
    }
  );

  // const {
  //   data: chartData,
  //   isLoading: chartDataLoading,
  //   isError: chartDataError,
  // } = useGetCarChartQuery(
  //   {
  //     stateCode: state,
  //     make: make === "All" ? "" : make,
  //     model: model === "All" ? "" : model,
  //     grade: grade === "All" ? "" : grade,
  //     timeline: timeline,
  //   },
  //   {
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  const {
    data: chartData,
    isLoading: chartDataLoading,
    isError: chartDataError,
  } = useGetCarChartCriteriaQuery(
    {
      stateCode: state,
      make: make === "All" ? "" : make,
      model: model === "All" ? "" : model,
      grade: grade === "All" ? "" : grade,
      timeline: timeline,
      body: bodyStyle === "All" ? "" : bodyStyle,
      high: priceRangeHigh,
      low: priceRangeLow,
      fuel: fuelType === "All" ? "" : fuelType,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // console.log(carChartCriteria);

  const {
    data: carBodyStyle,
    isLoading: carBodyStyleLoading,
    isFetching: carBodyStyleIsFetching,
  } = useGetCarBodyStyleQuery(
    {
      stateCode: state,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: carPriceRange,
    isLoading: carPriceRangeLoading,
    isFetching: carPriceRangeIsFetching,
    isError: carPriceRangeError,
  } = useGetCarPriceRangeQuery(
    {
      stateCode: state,
      bodyStyle: bodyStyle === "All" ? "" : bodyStyle,
    },
    {
      // skip: bodyStyle === "All",
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: carFuelType,
    isLoading: carFuelTypeLoading,
    isError: carFuelTypeError,
    isFetching: carFuelTypeIsFetching,
  } = useGetCarFuelTypeQuery(
    {
      stateCode: state,
      bodyStyle: bodyStyle === "All" ? "" : bodyStyle,
      high: priceRangeHigh,
      low: priceRangeLow,
    },
    {
      // skip: bo.dyStyle === "All",
      refetchOnMountOrArgChange: true,
    }
  );

  const isCarPriceRangeLoading =
    carPriceRangeLoading || carPriceRangeIsFetching;

  const isFuelTypeLoading = carFuelTypeLoading || carFuelTypeIsFetching;

  const isBodyStyleLoading = carBodyStyleLoading || carBodyStyleIsFetching;

  const isMakeLoading = isLoading || carMakeFetching;

  const isModelLoading = carModelLoading || carModelFetching;

  const isGradeLoading = carGradeLoading || carGradeFetching;

  /* Adding temp values for initial Load */

  useEffect(() => {
    const temp = {
      carAverageMinAndMax: {
        averagePrice: 0,
        minPrice: 0,
        maxPrice: 0,
        priceChange: 0,
        priceChangePercentage: 0,
        engine: null,
        current: 0,
        sponsoredDetails: null,
      },
      chartData: [],
      carImage: {
        found: false,
        url: undefined,
      },
    };

    const d = temp.chartData.map((item, index) => ({
      x: new Date(item.date).valueOf(),
      y: item.price,
      marker: {
        enabled: index === temp.chartData.length - 1,
        symbol: "circle",
      },
    }));

    const seriesName = getTooltipValue({
      make: make,
      model: model,
      grade: grade,
    });

    updateChartData({
      value: index,
      data: d,
      color: colors[index],
      name: seriesName,
      make: make,
      model: model,
      grade: grade,

      carAverageMinAndMax: temp.carAverageMinAndMax,
      carImage: temp.carImage,
    });
  }, []);

  /* Adding temp values for initial Load */

  useEffect(() => {
    const temp = {
      carAverageMinAndMax: {
        averagePrice: 0,
        minPrice: 0,
        maxPrice: 0,
        priceChange: 0,
        priceChangePercentage: 0,
        engine: null,
        current: 0,
        sponsoredDetails: null,
      },
      chartData: [],
      carImage: {
        found: false,
        url: undefined,
      },
    };

    const d = temp.chartData.map((item, index) => ({
      x: new Date(item.date).valueOf(),
      y: item.price,
      marker: {
        enabled: index === temp.chartData.length - 1,
        symbol: "circle",
      },
    }));

    const seriesName = getTooltipValue({
      make: make,
      model: model,
      grade: grade,
    });

    updateChartData({
      value: index,
      data: d,
      color: colors[index],
      name: seriesName,
      make: make,
      model: model,
      grade: grade,

      carAverageMinAndMax: temp.carAverageMinAndMax,
      carImage: temp.carImage,
    });
  }, []);

  useEffect(() => {
    if (!chartDataLoading && chartData) {
      const fetchedChartData = chartData.data.chartData;

      const d = fetchedChartData.map((item, index) => ({
        x: new Date(item.date).valueOf(),
        y: item.price,
        marker: {
          enabled: index === fetchedChartData.length - 1,
          symbol: "circle",
        },
      }));

      const seriesName = getTooltipValue({
        make: make,
        model: model,
        grade: grade,
      });

      updateChartData({
        value: index,
        data: d,
        color: colors[index],
        name: seriesName,
        make: make,
        model: model,
        grade: grade,
        carAverageMinAndMax: chartData.data.carAverageMinAndMax,
        carImage: chartData.data.carImage,
      });
    }
  }, [timeline, make, model, grade, index, chartData]);

  useEffect(() => {
    if (!carPriceRangeLoading && carPriceRange) {
      if (priceRangeHigh === 0) {
        updatePriceRange(
          "priceRange",
          `${carPriceRange?.data?.minPrice}-${carPriceRange?.data?.maxPrice}`,
          index
        );
      }

      if (priceRangeHigh !== 0) {
        updatePriceRange(
          "priceRange",
          `${priceRangeLow}-${priceRangeHigh}`,
          index
        );
      }

      setPriceRangeValue({
        maximum: carPriceRange?.data?.maxPrice,
        minimum: carPriceRange?.data?.minPrice,
      });
    }
  }, [carBodyStyleLoading, carPriceRange, state]);

  // useEffect(() => {
  //   if (!carPriceRangeLoading && carPriceRange) {
  //     // if (priceRange !== "All") {
  //     //   onChange(
  //     //     "priceRange",
  //     //     `${carPriceRange?.data?.minPrice}-${carPriceRange?.data?.maxPrice}`
  //     //   );
  //     //   setPriceRangeValue({
  //     //     maximum: carPriceRange?.data?.maxPrice,
  //     //     minimum: carPriceRange?.data?.minPrice,
  //     //   });
  //     // }

  //     if (priceRangeHigh !== 0) {
  //       onChange(
  //         "priceRange",
  //         `${carPriceRange?.data?.minPrice}-${carPriceRange?.data?.maxPrice}`
  //       );
  //       setPriceRangeValue({
  //         maximum: carPriceRange?.data?.maxPrice,
  //         minimum: carPriceRange?.data?.minPrice,
  //       });
  //     }
  //   }
  // }, []);

  if (isLoading) {
    return (
      <Grid2 container spacing={2}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid2 sm={12} md={4} key={index}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>
        ))}
      </Grid2>
    );
  }

  if (isError || carModelError || carGradeError || chartDataError) {
    return <Skeleton variant="rectangular" width={"100%"} height={200} />;
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          mb: { xs: "8px", md: "4px" },
        }}
        gap={2}
        style={{ marginTop: index > 0 ? "12px" : 0 }}
      >
        <Typography
          component="h5"
          sx={{
            padding: 0,
            margin: 0,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.01em",
          }}
        >
          {index === 0 ? "" : `Selection ${index + 1}`}
        </Typography>

        {index > 0 ? (
          <button
            style={{
              background: "none",
              margin: "0",
              padding: "0",
              outline: "none",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            type="button"
            onClick={() => {
              handleCarDeletion(index);
              removeCarFromChart(index);
            }}
            sx={{
              color: "#000",
              display: "flex",
              alignItems: "center",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              gap: "8px",
            }}
          >
            <MinusCircleIcon height="50px" color="#000" />
          </button>
        ) : null}
      </Stack>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={4}>
          <SelectDemo
            label="Body"
            name="bodyStyle"
            defaultOption={["All"]}
            options={carBodyStyle?.data}
            background={colors[index]}
            onChange={onChange}
            value={bodyStyle}
            isLoading={isBodyStyleLoading}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <PriceRangeDropdown
            label="Price Range"
            minimum={priceRangeValue.minimum}
            maximum={priceRangeValue.maximum}
            name="priceRange"
            defaultOption={["All"]}
            // disabled={bodyStyle === "All"}
            background={colors[index]}
            onChange={onChange}
            currentValue={priceRange === "All" ? "0-0" : priceRange}
            isLoading={isCarPriceRangeLoading}
            index={index}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <SelectDemo
            label="Fuel Type"
            name="fuelType"
            defaultOption={["All"]}
            options={carFuelType?.data}
            background={colors[index]}
            onChange={onChange}
            value={fuelType}
            isLoading={isFuelTypeLoading}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <SelectDemo
            label="Make"
            name="make"
            defaultOption={["All"]}
            options={carMake?.data}
            background={colors[index]}
            onChange={onChange}
            value={make}
            isLoading={isMakeLoading}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <SelectDemo
            label="Model"
            name="model"
            options={carModel?.data}
            defaultOption={["All"]}
            disabled={make === "All"}
            background={colors[index]}
            onChange={onChange}
            value={model}
            isLoading={isModelLoading}
          />
        </Grid2>
        <Grid2 xs={12} md={4}>
          <SelectDemo
            label="Grade"
            name="grade"
            options={carGrade?.data}
            defaultOption={["All"]}
            disabled={model === "All"}
            background={colors[index]}
            onChange={onChange}
            value={grade}
            isLoading={isGradeLoading}
          />
        </Grid2>
      </Grid2>
    </>
  );
};

export default SelectCarDetailsCriteria;
