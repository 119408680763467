import { Button, Container, Skeleton, Stack } from "@mui/material";
import SelectCarDetails from "@/sections/SelectCarForm/SelectCarDetails";
import ChartWrapper from "@/sections/SelectCarForm/ChartWrapper";
import { PlusCircleIcon } from "lucide-react";
import { useCarLocation } from "@/hooks/use-car-location";
import ErrorPage from "@/pages/ErrorPage";
import { styled } from "@mui/material/styles";
import SelectCarDetailsCriteria from "@/sections/SelectCarForm/search-by-criteria/select-car-details-criteria";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const StyledPlusCircleIcon = styled(PlusCircleIcon)(({ theme, disabled }) => ({
  height: "25px",
  color: "#000",
  opacity: disabled ? 0.3 : 1,
}));

export function SearchByCriteria() {
  const { isValid, values, handleCarAddition } = useCarLocation();

  const {
    make,
    model,
    grade,
    state,
    timeline,
    isLoading,
    bodyStyle,
    priceRange,
    fuelType,
  } = values;

  // console.log(values);

  if (isLoading)
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Grid2 container spacing={2}>
          <Grid2 sm={12} lg={4}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>

          <Grid2 sm={12} lg={4}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>

          <Grid2 sm={12} lg={4}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>

          <Grid2 sm={12} lg={4}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>
          <Grid2 sm={12} lg={4}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>
          <Grid2 sm={12} lg={4}>
            <Skeleton variant="rectangular" width={"100%"} height={50} />
          </Grid2>
        </Grid2>
      </Container>
    );

  if (!isValid) return <ErrorPage />;

  // console.log(priceRange);

  return (
    <>
      {make.map((car, index) => (
        <SelectCarDetailsCriteria
          make={make[index]}
          model={model[index]}
          grade={grade[index]}
          bodyStyle={bodyStyle[index]}
          priceRange={priceRange[index]}
          fuelType={fuelType[index]}
          state={state}
          timeline={timeline}
          index={index}
          key={index}
        />
      ))}
      <Stack
        alignItems="flex-end"
        justifyContent="flex-end"
        sx={{
          mt: "8px",
        }}
        direction="row"
      >
        <Button
          onClick={() => handleCarAddition()}
          sx={{
            color: "#000",
            display: "flex",
            alignItems: "center",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            gap: "8px",
            "& :disabled": {
              cursor: "not-allowed",
            },
          }}
          disabled={make.length === 4}
        >
          <StyledPlusCircleIcon disabled={make.length === 4} />
          <span>Compare Models</span>
        </Button>
      </Stack>

      <ChartWrapper />
    </>
  );
}
