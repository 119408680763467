import { createSlice } from "@reduxjs/toolkit";
// import jwt_decode from "jwt-decode";

const userStateSlice = createSlice({
  name: "userState",
  initialState: {
    selectedState: {
      name: "",
      code: "",
    },
    isStateChosen: false,
    isSelectStateModalVisible: false,
  },
  reducers: {
    setSelectedState: (state, action) => {
      const { name, code } = action.payload;
      state.selectedState.name = name;
      state.selectedState.code = code;
      state.isStateChosen = true;
    },

    toggleSelectedStateModal: (state) => {
      state.isSelectStateModalVisible = !state.isSelectStateModalVisible;
    },

    showSelectedStateModal: (state) => {
      state.isSelectStateModalVisible = true;
    },
  },
});

export const {
  setSelectedState,
  toggleSelectedStateModal,
  showSelectedStateModal,
} = userStateSlice.actions;

export default userStateSlice.reducer;

export const selectSelectedState = (state) => state.userState.selectedState;
export const selectIsSelectStateModalVisible = (state) =>
  state.userState.isSelectStateModalVisible;
export const selectIsStateChosen = (state) => state.userState.isStateChosen;
