import { Container, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { useGetPublicImagesQuery } from "@/app/features/publicData/publicDataApiSlice";
import { IMAGE_CDN_LINK } from "@/constants/links";

const StyledList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: 0,
  color: "#333",

  border: "1px solid #bfb7b7",
  borderRadius: "4px",
}));

const StyledItem = styled("li")(({ theme, isBackground }) => ({
  ":first-of-type": {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },

  backgroundColor: isBackground ? "#eee" : "#fff",
  padding: "10px 15px",

  ":last-child": {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

const StyledLink = styled("a")(({ theme }) => ({
  whiteSpace: "pre-wrap",
  whiteSpace: "-moz-pre-wrap",
  whiteSpace: "-pre-wrap",
  whiteSpace: "-o-pre-wrap",
  wordWrap: "break-word",
}));

function createUrl(link) {
  const imageName = link.split("/").pop();
  return `${IMAGE_CDN_LINK}/public/${imageName}`;
}

const PublicDataPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { isLoading, isError, data } = useGetPublicImagesQuery();

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>The Beep | Public Images for The Beep</title>
      </Helmet>
      <Typography mt={4} mb={4} variant="h4">
        Public Images for The Beep
      </Typography>
      Number of Images in this list: {data.resultList.length}
      <StyledList>
        {data.resultList.map((link, index) => (
          <StyledItem isBackground={index % 2 === 0} key={index}>
            <StyledLink target="_blank" href={createUrl(link)}>
              {createUrl(link)}
            </StyledLink>
          </StyledItem>
        ))}
      </StyledList>
    </Container>
  );
};

export default PublicDataPage;
