import { Fragment } from "react";
import { ChevronDown, RefreshCw } from "lucide-react";
import { Listbox } from "@headlessui/react";
import "./SelectDemo.css";
import { Scrollbar } from "@/components/scrollbar";

function SelectDemo({
  options,
  name,
  label,
  disabled = false,
  background,
  defaultOption,
  onChange,
  value,
  isLoading = false,
}) {
  function handleSelect(v) {
    if (onChange) onChange(name, v);
  }

  // const isLoading = true;

  // if (name === "model")
  //   return <Skeleton variant="rectangular" width={"100%"} height={"100%"} />;

  // if (isLoading) {
  //   return <Skeleton variant="rectangular" width={"100%"} height={"100%"} />;
  // }

  return (
    <Listbox
      className={`select-container ${disabled || isLoading ? "disabled" : ""}`}
      style={{
        "--clr-primary": background,
      }}
      as="div"
      value={value}
      onChange={handleSelect}
      disabled={disabled || isLoading}
    >
      <Listbox.Button as="div" className="select-container-button">
        <p style={{ fontWeight: "700" }}>{label}</p>
        <span>
          {isLoading ? (
            <RefreshCw className="loader" />
          ) : (
            <>
              <p className="select-container-value">{value}</p>
              <ChevronDown className="dropdown" />
            </>
          )}
        </span>
      </Listbox.Button>
      <Scrollbar className="select-scrollbar">
        <Listbox.Options className="select-container-list">
          {defaultOption.map((option) => (
            <Listbox.Option value={option} key={option} as={Fragment}>
              {() => <li className="select-container-item">{option}</li>}
            </Listbox.Option>
          ))}
          {options?.map((option, index) => (
            <Listbox.Option key={index} value={option} as={Fragment}>
              {() => <li className="select-container-item">{option}</li>}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Scrollbar>
    </Listbox>
  );
}

export default SelectDemo;
