// import { apiSlice } from "../../app/api/apiSlice";

import { apiSlice } from "../../api/apiSlice";

export const publicDataApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicImages: builder.query({
      query: () => "/api/v1/public-image",
    }),
  }),
});

export const { useGetPublicImagesQuery } = publicDataApiSlice;
