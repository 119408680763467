import { Fragment, useEffect } from "react";
import {
  useGetCarChartQuery,
  useGetCarGradeQuery,
  useGetCarMakeQuery,
  useGetCarModelQuery,
} from "@/app/features/car/carApiSlice";
import { Skeleton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SelectDemo from "@/components/Dropdown/SelectDemo";

import { colors } from "@/constants/colors";
import useCarStore from "@/context/carStore";
import { MinusCircleIcon } from "lucide-react";
import { getTooltipValue } from "@/pages/Homepage/components/chartOptions";
import { useCarLocation } from "@/hooks/use-car-location";
import { useUpdatePriceAnalyticsMutation } from "@/app/features/analytics/analyticsApiSlice";

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },

  "& > div": {
    flex: 1,
  },
}));

const SelectCarDetails = ({ make, model, grade, state, timeline, index }) => {
  const { updateSearchParams, handleCarDeletion } = useCarLocation();
  const { setChartData: updateChartData, removeCarFromChart } = useCarStore(
    (store) => store
  );

  const [updatePriceAnalytics] = useUpdatePriceAnalyticsMutation();

  const {
    data: carMake,
    isLoading,
    isError,
  } = useGetCarMakeQuery(
    {
      stateCode: state,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: carModel, isError: carModelError } = useGetCarModelQuery(
    {
      stateCode: state,
      make: make,
    },
    {
      skip: make === "All",
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: carGrade, isError: carGradeError } = useGetCarGradeQuery(
    {
      stateCode: state,
      make: make,
      model: model,
    },
    {
      skip: model === "All",
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: chartData,
    isLoading: chartDataLoading,
    isError: chartDataError,
  } = useGetCarChartQuery(
    {
      stateCode: state,
      make: make === "All" ? "" : make,
      model: model === "All" ? "" : model,
      grade: grade === "All" ? "" : grade,
      timeline: timeline,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  /* Adding temp values for initial Load */

  useEffect(() => {
    const temp = {
      carAverageMinAndMax: {
        averagePrice: 0,
        minPrice: 0,
        maxPrice: 0,
        priceChange: 0,
        priceChangePercentage: 0,
        engine: null,
        current: 0,
        sponsoredDetails: null,
      },
      chartData: [],
      carImage: {
        found: false,
        url: undefined,
      },
    };

    const d = temp.chartData.map((item, index) => ({
      x: new Date(item.date).valueOf(),
      y: item.price,
      marker: {
        enabled: index === temp.chartData.length - 1,
        symbol: "circle",
      },
    }));

    const seriesName = getTooltipValue({
      make: make,
      model: model,
      grade: grade,
    });

    updateChartData({
      value: index,
      data: d,
      color: colors[index],
      name: seriesName,
      make: make,
      model: model,
      grade: grade,

      carAverageMinAndMax: temp.carAverageMinAndMax,
      carImage: temp.carImage,
    });
  }, []);

  /* Adding temp values for initial Load */

  useEffect(() => {
    const temp = {
      carAverageMinAndMax: {
        averagePrice: 0,
        minPrice: 0,
        maxPrice: 0,
        priceChange: 0,
        priceChangePercentage: 0,
        engine: null,
        current: 0,
        sponsoredDetails: null,
      },
      chartData: [],
      carImage: {
        found: false,
        url: undefined,
      },
    };

    const d = temp.chartData.map((item, index) => ({
      x: new Date(item.date).valueOf(),
      y: item.price,
      marker: {
        enabled: index === temp.chartData.length - 1,
        symbol: "circle",
      },
    }));

    const seriesName = getTooltipValue({
      make: make,
      model: model,
      grade: grade,
    });

    updateChartData({
      value: index,
      data: d,
      color: colors[index],
      name: seriesName,
      make: make,
      model: model,
      grade: grade,

      carAverageMinAndMax: temp.carAverageMinAndMax,
      carImage: temp.carImage,
    });
  }, []);

  useEffect(() => {
    if (!chartDataLoading && chartData) {
      const fetchedChartData = chartData.data.chartData;

      const d = fetchedChartData.map((item, index) => ({
        x: new Date(item.date).valueOf(),
        y: item.price,
        marker: {
          enabled: index === fetchedChartData.length - 1,
          symbol: "circle",
        },
      }));

      const seriesName = getTooltipValue({
        make: make,
        model: model,
        grade: grade,
      });

      updateChartData({
        value: index,
        data: d,
        color: colors[index],
        name: seriesName,
        make: make,
        model: model,
        grade: grade,
        carAverageMinAndMax: chartData.data.carAverageMinAndMax,
        carImage: chartData.data.carImage,
      });
    }
  }, [timeline, make, model, grade, index, chartData]);

  if (isLoading) {
    return (
      <Stack spacing={3}>
        <Skeleton variant="rectangular" width={"100%"} height={50} />
        <Skeleton variant="rectangular" width={"100%"} height={50} />
        <Skeleton variant="rectangular" width={"100%"} height={50} />
        <Skeleton variant="rectangular" width={"100%"} height={50} />
      </Stack>
    );
  }

  if (isError || carModelError || carGradeError || chartDataError) {
    return <Skeleton variant="rectangular" width={"100%"} height={200} />;
  }

  const onChange = (name, value) => {
    updateSearchParams(name, value, index);

    if (value !== "All") {
      let eventRef = "";
      if (name === "make") {
        eventRef = `${value}`;
      } else if (name === "model") {
        eventRef = `${make}|${value}`;
      } else if (name === "grade") {
        eventRef = `${make}|${model}|${value}`;
      }

      updatePriceAnalytics({
        eventType: name,
        eventValue: value,
        state,
        eventRef: eventRef,
        sponsored: name === "grade",
      });
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          mb: { xs: "8px", md: "4px" },
        }}
        gap={2}
        style={{ marginTop: index > 0 ? "12px" : 0 }}
      >
        <Typography
          component="h5"
          sx={{
            padding: 0,
            margin: 0,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.01em",
          }}
        >
          {index === 0 ? "Please make a selection" : `Selection ${index + 1}`}
        </Typography>

        {index > 0 ? (
          <button
            style={{
              background: "none",
              margin: "0",
              padding: "0",
              outline: "none",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            type="button"
            onClick={() => {
              handleCarDeletion(index);
              removeCarFromChart(index);
            }}
            sx={{
              color: "#000",
              display: "flex",
              alignItems: "center",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              gap: "8px",
            }}
          >
            <MinusCircleIcon height="50px" color="#000" />
          </button>
        ) : null}
      </Stack>

      <StyledStack>
        <SelectDemo
          label="Make"
          // name={`cars.${index}.make`}
          name="make"
          defaultOption={["All"]}
          options={carMake?.data}
          background={colors[index]}
          onChange={onChange}
          value={make}
        />

        <SelectDemo
          label="Model"
          // name={`cars.${index}.model`}
          name="model"
          options={carModel?.data}
          defaultOption={["All"]}
          disabled={make === "All"}
          background={colors[index]}
          onChange={onChange}
          value={model}
        />

        <SelectDemo
          label="Grade"
          // name={`cars.${index}.grade`}
          name="grade"
          options={carGrade?.data}
          defaultOption={["All"]}
          disabled={model === "All"}
          background={colors[index]}
          onChange={onChange}
          value={grade}
        />
      </StyledStack>
    </>
  );
};

export default SelectCarDetails;
