import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import { Box, Rating, Stack } from "@mui/material";
import { MoveDown } from "lucide-react";
import { useCarLocation } from "@/hooks/use-car-location";
import useCarStore from "@/context/carStore";
import { formatCurrency, formatNumberWithSign } from "@/helpers/utils";
import {
  useUpdateAdvertisementAnalyticsMutation,
  useUpdateSponsoredAnalyticsMutation,
  useUpdateSponsoredAnalyticsViewsMutation,
} from "@/app/features/analytics/analyticsApiSlice";
import { useParams } from "react-router-dom";
import ShareCarButton from "@/pages/Homepage/components/ShareCarButton";
import { IMAGE_CDN_LINK } from "@/constants/links";

const BASE_IMAGE_URL = `${IMAGE_CDN_LINK}/advertisement`;

function getPercentageColor(priceChange) {
  if (priceChange < 0) return "#44BA4E";
  else if (priceChange > 0) return "#EC4D3F";
  else return "#666666";
}

const OverviewContainer = styled("div")(({ theme, hasImage }) => ({
  background: "#dbeaf6",
  borderRadius: "4px",
  padding: "16px",
  // width: "360px",
  // paddingTop: hasImage ? "55px" : "16px",
  paddingTop: hasImage ? "52px" : "16px",
  height: "max-content",
  position: "relative",
}));

const OverviewContainerImage = styled("img")`
  width: 230px;
  height: 115px;
  display: block;

  /* width: 200px; */
  object-fit: contain;
`;

// const OverviewContainerImage = styled("img")`
//   position: absolute;
//   height: 90px;
//   top: -40px;
//   left: 50%;
//   transform: translateX(-50%);

//   /* width: 200px; */
//   object-fit: contain;
// `;

const Heading = styled("p")(({ hasImage, theme }) => ({
  // marginTop: "16px !important",
  color: "#0d0d0d",
  fontWeight: 400,
  fontSize: "19px",
  lineHeight: "normal",
  margin: 0,
  padding: 0,
  textAlign: "center",
  marginTop: hasImage ? "32px" : "",

  //   [theme.breakpoints.down("md")]: {
  //     width: "65%",
  //     alignSelf: "flex-start",
  //     position: "sticky",
  //     top: "100px",
  //   },
}));

const Subheading = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 300,
  fontSize: "16px",
  lineHeight: "24px",
  margin: 0,
  padding: 0,
  textAlign: "center",
}));

const OverviewHeader = styled("div")`
  border-bottom: 1px solid #b7d6ed;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const Divider = styled("hr")`
  border: none;
  outline: none;
  border-bottom: 1px solid #b7d6ed;
`;

const VehicleTitle = styled("p")(({ theme }) => ({
  color: "#666666",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "25.60px",
  letterSpacing: "0.08px",
  wordWrap: "break-word",
  textAlign: "center",
  flex: 1,
  margin: "0px",
}));

const CurrentCarPrice = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: "400",
  fontSize: "19px",
  lineHeight: "normal",
  margin: 0,
  padding: 0,
  textAlign: "center",
}));

const VehicleValue = styled("p")(({ theme }) => ({
  color: "#0D0D0D",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "25.60px",
  letterSpacing: "0.08px",
  wordWrap: "break-word",
  textAlign: "center",
  flex: 1,
  margin: "0px",
}));

const Title = styled("p")(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "160%",
  color: "#666666",
  letterSpacing: "0.005em",
  margin: 0,
  padding: 0,
}));

const MoveDownIcon = styled(MoveDown)(({ theme, pricechange }) => ({
  width: "13px",
  height: "auto",
  strokeWidth: "3.5px",
  transform: pricechange < 0 ? "initial" : "rotate(180deg)",
}));

const Value = styled("p")(({ theme }) => ({
  color: "#0d0d0d",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "160%",
  margin: 0,
  padding: 0,
  letterSpacing: "0.005em",
  display: "flex",
  alignItems: "center",
}));

const LinkValue = styled("a")(({ theme }) => ({
  color: "#0d0d0d",
  textDecoration: "none",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "120%",
  margin: 0,
  padding: 0,
  letterSpacing: "0.005em",
  display: "flex",
  alignItems: "center",
}));

const PercentageChange = styled("span")(({ pricechange }) => ({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "160%",
  letterSpacing: "0.005em",
  color: getPercentageColor(pricechange),
  margin: 0,
  padding: 0,

  display: "flex",
  alignItems: "center",
}));

const StyledRating = styled(Rating)({
  fontSize: "14px",
  "& .MuiRating-iconFilled": {
    color: "#0D0D0D",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const AdImageWrapper = styled("div")(({ theme }) => ({
  // position: "relative",
  // width: "100%",
  // height: "60px",
  // [theme.breakpoints.up("md")]: {
  //   height: "60px",
  // },
}));

const AdImageDesktop = styled("img")(({ theme }) => ({
  // position: "absolute",
  width: "100%",

  display: "none",
  height: "100%",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const AdImageMobile = styled("img")(({ theme }) => ({
  // position: "absolute",
  width: "100%",
  display: "block",

  height: "100%",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const AdImageDesktopText = styled("p")(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  right: "0px",
  padding: "2px 6px",

  margin: "0",

  display: "none",
  textTransform: "uppercase",

  background: "#ccd5dd",
  color: "#1b0d04",

  fontSize: "12px",

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const AdImageMobileText = styled("p")(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  right: "0px",
  padding: "2px 6px",

  display: "block",
  textTransform: "uppercase",

  margin: "0",

  background: "#ccd5dd",
  color: "#1b0d04",

  fontSize: "10px",

  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const OverviewBox = () => {
  const { chartData } = useCarStore();

  const { values } = useCarLocation();
  const { make, model, grade } = values;

  return (
    <Box className="overview-container">
      <Stack gap={5} mt={2}>
        {chartData.map((car, index) =>
          values.filterType === "model" ? (
            <SearchByModelOverviewBox
              key={index}
              car={car}
              index={index}
              make={make}
              model={model}
              grade={grade}
              timeline={values.timeline}
            />
          ) : (
            <SearchByCriteriaOverviewBox
              key={index}
              car={car}
              index={index}
              make={make}
              model={model}
              grade={grade}
              filterType={values.filterType}
              bodyStyle={values.bodyStyle}
              fuelType={values.fuelType}
              priceRange={values.priceRange}
              timeline={values.timeline}
            />
          )
        )}
      </Stack>
      <ShareCarButton />
    </Box>
  );
};

// const SearchByModelOverviewBox = ({ car, make, model, grade, index }) => {
//   return (
//     <OverviewContainer>
//       {car?.carImage?.found && (
//         <OverviewContainerImage
//           src={`${IMAGE_CDN_LINK}/${car?.carImage?.url}`}
//           alt="car"
//         />
//       )}
//       <OverviewHeader>
//         <Heading hasImage={car?.carImage?.found}>
//           {make[index]} {model[index] !== "All" && model[index]}{" "}
//           {grade[index] !== "All" && grade[index]}
//         </Heading>
//         <Subheading>
//           {car?.carAverageMinAndMax?.vehicle?.engine &&
//             car?.carAverageMinAndMax?.vehicle?.engine}
//         </Subheading>
//       </OverviewHeader>
//       <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
//         <Stack direction="row" alignItems="center" gap={1}>
//           <Title>Current</Title>
//           <Value>
//             {formatCurrency({
//               price: car?.carAverageMinAndMax?.current || 0,
//             })}
//           </Value>
//         </Stack>

//         <Stack direction="row" alignItems="center" gap={1}>
//           <Title>High</Title>
//           <Value>
//             {formatCurrency({
//               price: car?.carAverageMinAndMax?.maxPrice || 0,
//             })}
//           </Value>
//         </Stack>
//       </Stack>
//       <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
//         <Stack direction="row" alignItems="center" gap={1}>
//           <Title>Avg</Title>
//           <Value>
//             {formatCurrency({
//               price: car?.carAverageMinAndMax?.averagePrice || 0,
//             })}
//           </Value>
//         </Stack>
//         <Stack direction="row" alignItems="center" gap={1}>
//           <Title>Low</Title>
//           <Value>
//             {formatCurrency({
//               price: car?.carAverageMinAndMax?.minPrice || 0,
//             })}
//           </Value>
//         </Stack>
//       </Stack>
//       <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
//         <Stack direction="row" alignItems="center" gap={1}>
//           <Title>Change</Title>
//           <Value>
//             {formatCurrency({
//               price: car?.carAverageMinAndMax?.priceChange || 0,
//               sign: true,
//             })}
//           </Value>
//           <PercentageChange pricechange={car?.carAverageMinAndMax?.priceChange}>
//             (
//             {car?.carAverageMinAndMax?.priceChange !== 0 && (
//               <MoveDownIcon
//                 pricechange={car?.carAverageMinAndMax?.priceChange}
//               />
//             )}
//             {formatNumberWithSign(
//               car?.carAverageMinAndMax?.priceChangePercentage
//             )}
//             %)
//           </PercentageChange>
//         </Stack>
//       </Stack>
//       {car?.carAverageMinAndMax?.sponsoredDetails?.linkText ? (
//         <OverviewBoxAdSection car={car} />
//       ) : null}
//     </OverviewContainer>
//   );
// };

const SearchByModelOverviewBox = ({
  car,
  make,
  model,
  grade,
  index,
  timeline,
}) => {
  return (
    <OverviewContainer hasImage={car?.carImage?.found}>
      {car?.carImage?.found && <OverviewBoxImageSlider car={car} />}
      <OverviewHeader>
        <Heading hasImage={car?.carImage?.found}>
          {make[index]} {model[index] !== "All" && model[index]}{" "}
          {grade[index] !== "All" && grade[index]}
        </Heading>
        {/* <Subheading>
          {car?.carAverageMinAndMax?.vehicle?.engine &&
            car?.carAverageMinAndMax?.vehicle?.engine}
        </Subheading> */}
      </OverviewHeader>

      {car?.carAverageMinAndMax?.vehicle ? (
        <OverviewHeader>
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Body Style</VehicleTitle>
            <VehicleValue>{car?.carAverageMinAndMax?.car?.body}</VehicleValue>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%", margin: "0px", padding: "0px" }}
          >
            <VehicleTitle>Fuel Type</VehicleTitle>
            <VehicleValue>{car?.carAverageMinAndMax?.car?.fuel}</VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Engine</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.engine}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Power</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.power}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Transmission</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.transmission}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Drivetrain</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.drivetrain}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>0-100km/h</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.speed}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Fuel Consumption¹</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.consumption}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>Electric Range</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.erange}
            </VehicleValue>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ width: "100%" }}
          >
            <VehicleTitle>ANCAP Safety</VehicleTitle>
            <VehicleValue>
              {car?.carAverageMinAndMax?.vehicle?.ancap === -1 ? (
                "Not Rated"
              ) : (
                <StyledRating
                  name="half-rating-read"
                  value={car?.carAverageMinAndMax?.vehicle?.ancap || 0}
                  precision={0.5}
                  readOnly
                />
              )}
            </VehicleValue>
          </Stack>
        </OverviewHeader>
      ) : null}

      <Stack
        direction="column"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%", marginBottom: "10px" }}
      >
        <VehicleTitle>Current Est Drive Away Price</VehicleTitle>
        <CurrentCarPrice>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.current || 0,
          })}
        </CurrentCarPrice>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} Average</VehicleTitle>
        <VehicleValue>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.averagePrice || 0,
          })}
        </VehicleValue>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} High</VehicleTitle>
        <VehicleValue>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.maxPrice || 0,
          })}
        </VehicleValue>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} Low</VehicleTitle>
        <VehicleValue>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.minPrice || 0,
          })}
        </VehicleValue>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} Change</VehicleTitle>
        <VehicleValue>
          <Stack
            direction="row"
            columnGap={0.5}
            style={{ justifyContent: "center" }}
          >
            <span>
              {formatCurrency({
                price: car?.carAverageMinAndMax?.priceChange || 0,
                sign: true,
              })}
            </span>
            <PercentageChange
              pricechange={car?.carAverageMinAndMax?.priceChange}
            >
              (
              <MoveDownIcon
                pricechange={car?.carAverageMinAndMax?.priceChange}
              />{" "}
              {formatNumberWithSign(
                car?.carAverageMinAndMax?.priceChangePercentage
              )}
              %)
            </PercentageChange>
          </Stack>
        </VehicleValue>
      </Stack>
      {car?.carAverageMinAndMax?.sponsoredDetails ||
      car?.carAverageMinAndMax?.advertisement ? (
        <OverviewBoxAdSection car={car} />
      ) : null}
    </OverviewContainer>
  );
};

const SearchByCriteriaOverviewBox = ({
  car,
  make,
  model,
  grade,
  index,
  bodyStyle,
  fuelType,
  timeline,
}) => {
  return (
    <OverviewContainer hasImage={car?.carImage?.found}>
      {car?.carImage?.found && <OverviewBoxImageSlider car={car} />}
      <OverviewHeader>
        <Heading hasImage={car?.carImage?.found}>
          {make[index]} {model[index] !== "All" && model[index]}{" "}
          {grade[index] !== "All" && grade[index]}
        </Heading>
        <Subheading>
          {car?.carAverageMinAndMax?.engine && car?.carAverageMinAndMax?.engine}
        </Subheading>
      </OverviewHeader>

      <OverviewHeader>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ width: "100%" }}
        >
          <VehicleTitle>Body Style</VehicleTitle>
          <VehicleValue>
            {car?.carAverageMinAndMax?.car?.body ?? bodyStyle[index]}
          </VehicleValue>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ width: "100%", margin: "0px", padding: "0px" }}
        >
          <VehicleTitle>Fuel Type</VehicleTitle>
          <VehicleValue>
            {car?.carAverageMinAndMax?.car?.fuel ?? fuelType[index]}
          </VehicleValue>
        </Stack>

        {car?.carAverageMinAndMax?.vehicle ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>Engine</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.engine}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>Power</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.power}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>Transmission</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.transmission}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>Drivetrain</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.drivetrain}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>0-100km/h</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.speed}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>Fuel Consumption¹</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.consumption}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>Electric Range</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.erange}
              </VehicleValue>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ width: "100%" }}
            >
              <VehicleTitle>ANCAP Safety</VehicleTitle>
              <VehicleValue>
                {car?.carAverageMinAndMax?.vehicle?.ancap === -1 ? (
                  "Not Rated"
                ) : (
                  <StyledRating
                    name="half-rating-read"
                    value={car?.carAverageMinAndMax?.vehicle?.ancap || 0}
                    precision={0.5}
                    readOnly
                  />
                )}
              </VehicleValue>
            </Stack>
          </>
        ) : null}
      </OverviewHeader>

      <Stack
        direction="column"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%", marginBottom: "10px" }}
      >
        <VehicleTitle>Current Est Drive Away Price</VehicleTitle>
        <CurrentCarPrice>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.current || 0,
          })}
        </CurrentCarPrice>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} Average</VehicleTitle>
        <VehicleValue>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.averagePrice || 0,
          })}
        </VehicleValue>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} High</VehicleTitle>
        <VehicleValue>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.maxPrice || 0,
          })}
        </VehicleValue>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} Low</VehicleTitle>
        <VehicleValue>
          {formatCurrency({
            price: car?.carAverageMinAndMax?.minPrice || 0,
          })}
        </VehicleValue>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ width: "100%" }}
      >
        <VehicleTitle>{timeline} Change</VehicleTitle>
        <VehicleValue>
          <Stack
            direction="row"
            columnGap={0.5}
            style={{ justifyContent: "center" }}
          >
            <span>
              {formatCurrency({
                price: car?.carAverageMinAndMax?.priceChange || 0,
                sign: true,
              })}
            </span>
            <PercentageChange
              pricechange={car?.carAverageMinAndMax?.priceChange}
            >
              (
              <MoveDownIcon
                pricechange={car?.carAverageMinAndMax?.priceChange}
              />{" "}
              {formatNumberWithSign(
                car?.carAverageMinAndMax?.priceChangePercentage
              )}
              %)
            </PercentageChange>
          </Stack>
        </VehicleValue>
      </Stack>

      {car?.carAverageMinAndMax?.sponsoredDetails ||
      car?.carAverageMinAndMax?.advertisement ? (
        <OverviewBoxAdSection car={car} />
      ) : null}
    </OverviewContainer>
  );
};

const OverviewBoxAdSection = ({ car }) => {
  const { state } = useParams();

  // state to track currently tracked ad view and make sure that double track is not happening
  const [currentlyTrackedAdView, setCurrentlyTrackedAdView] = useState("");

  const [
    currentlyTrackedAdvertisementView,
    setCurrentlyTrackedAdvertisementView,
  ] = useState("");

  const [updateSponsoredAnalytics] = useUpdateSponsoredAnalyticsMutation();

  const [updateSponsoredAnalyticsViews] =
    useUpdateSponsoredAnalyticsViewsMutation();

  const [updateAdvertisementAnalytics] =
    useUpdateAdvertisementAnalyticsMutation();

  const handleAdClickAnalytics = ({ link, make, model, grade }) => {
    const eventRef = `${make}${model !== "All" ? "|" + model : ""}${
      grade !== "All" ? "|" + grade : ""
    }`;

    updateSponsoredAnalytics({
      eventType: "sponsorDealerClick",
      eventRef: eventRef,
      eventValue: link,
      state,
    });
  };

  useEffect(() => {
    const sponsoredData = Object.assign(
      car?.carAverageMinAndMax?.sponsoredDetails ?? {}
    );

    const advertisementData = Object.assign(
      car?.carAverageMinAndMax?.advertisement ?? {}
    );

    // const tempCar = Object.assign(car);

    const eventRef = `${car?.make}${
      car?.model !== "All" ? "|" + car?.model : ""
    }${car?.grade !== "All" ? "|" + car?.grade : ""}`;

    if (
      car?.make !== "All" &&
      // car?.model !== "All" &&
      // car?.grade !== "All" &&
      Object.keys(sponsoredData).length > 0 &&
      currentlyTrackedAdView !== eventRef
    ) {
      setCurrentlyTrackedAdView(eventRef);

      updateSponsoredAnalyticsViews({
        eventType: "sponsorDealerView",
        eventRef: eventRef,
        eventValue: sponsoredData.linkText,
        state,
      });
    }

    if (
      car?.make !== "All" &&
      // car?.model !== "All" &&
      // car?.grade !== "All" &&
      Object.keys(advertisementData).length > 0 &&
      currentlyTrackedAdvertisementView !== eventRef
    ) {
      setCurrentlyTrackedAdvertisementView(eventRef);

      updateAdvertisementAnalytics({
        eventType: "advertisementView",
        eventValue: car?.carAverageMinAndMax?.advertisement?.adText,
        eventRef: car?.carAverageMinAndMax?.advertisement?.adLink,
        state,
      });
    }
  }, [car, state]);

  return (
    <Box>
      <Divider />

      <Title
        sx={{
          fontSize: "0.8rem",
          textTransform: "uppercase",
        }}
      >
        advertisement
      </Title>

      {car?.carAverageMinAndMax?.advertisement && (
        <a
          href={car?.carAverageMinAndMax?.advertisement?.adLink}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            try {
              updateAdvertisementAnalytics({
                eventType: "advertisementClick",
                eventValue: car?.carAverageMinAndMax?.advertisement?.adText,
                eventRef: car?.carAverageMinAndMax?.advertisement?.adLink,
                state,
              });
            } catch (error) {
              console.log(error);
            }
          }}
          style={{
            margin: "10px 0",
            display: "block",
          }}
        >
          <AdImageWrapper>
            <AdImageDesktop
              src={`${BASE_IMAGE_URL}/${car?.carAverageMinAndMax?.advertisement?.adDesktopBanner}`}
              alt={car?.carAverageMinAndMax?.advertisement?.adDesktopBanner}
            />
            <AdImageMobile
              src={`${BASE_IMAGE_URL}/${car?.carAverageMinAndMax?.advertisement?.adMobileBanner}`}
              alt={car?.carAverageMinAndMax?.advertisement?.adMobileBanner}
            />
          </AdImageWrapper>
        </a>
      )}

      <LinkValue
        href={car?.carAverageMinAndMax?.sponsoredDetails?.linkAddress}
        target="_blank"
        sx={{ display: "inline" }}
        onClick={() => {
          handleAdClickAnalytics({
            link: car?.carAverageMinAndMax?.sponsoredDetails?.linkText,
            make: car.make,
            model: car.model,
            grade: car.grade,
          });
        }}
      >
        {car?.carAverageMinAndMax?.sponsoredDetails?.linkText}
      </LinkValue>
    </Box>
  );
};

function OverviewBoxImageSlider({ car }) {
  const images = car?.carImage?.images || [];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Function to cycle through the images
  const handleImageClick = () => {
    if (images.length > 1) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  return images.length > 0 ? (
    <div
      onClick={handleImageClick}
      style={{
        cursor: "pointer",
        zIndex: 15,
        pointerEvents: "auto",
        position: "absolute",
        top: "-35px",
        left: "50%",
        transform: "translateX(-50%)",
        overflow: "hidden",
      }}
    >
      {/* Render all the images but only display the current one */}
      {images.map((image, index) => (
        <OverviewContainerImage
          key={image.imageName}
          src={`${IMAGE_CDN_LINK}/car-images/${image?.imageName}`}
          alt="car"
          style={{
            display: index === currentImageIndex ? "block" : "none",
          }}
        />
      ))}
    </div>
  ) : null;
}

export default OverviewBox;
