import DefaultLayout from "@/layouts/DefaultLayout/default-layout";
import HomeLayout from "@/layouts/HomeLayout/HomeLayout";
import AboutPage from "@/pages/AboutPage";
import AdvertisePage from "@/pages/AdvertisePage";
import ContactPage from "@/pages/ContactPage";
import ErrorPage from "@/pages/ErrorPage";
import Homepage from "@/pages/Homepage/Homepage";
import MediaPage from "@/pages/MediaPage";
import OfferPage from "@/pages/OfferPage";
import PricingWorks from "@/pages/PricingWorks";
import PrivacyPage from "@/pages/PrivacyPage";
import PublicDataPage from "@/pages/PublicDataPage";
import TermsPage from "@/pages/TermsPage";
import DefaultSelectCarForm from "@/sections/DefaultSelectForm/DefaultSelectForm";
import SelectCarForm from "@/sections/SelectCarForm/SelectCarForm";
import DefaultOfferForm from "@/sections/SelectOfferTracker/DefaultOfferForm";
import SelectOfferForm from "@/sections/SelectOfferTracker/SelectOfferForm";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/price-tracker/state" />} />

      <Route element={<HomeLayout />}>
        <Route path="/price-tracker/state" element={<Homepage />}>
          <Route index element={<DefaultSelectCarForm />} />
          <Route path=":state" element={<SelectCarForm />} />
        </Route>
        <Route path="/offer-tracker/state/" element={<OfferPage />}>
          <Route index element={<DefaultOfferForm />} />
          <Route path=":state" element={<SelectOfferForm />} />
        </Route>

        <Route
          path="/howpricingworks/state/"
          element={<DefaultLayout route={"howpricingworks"} />}
        >
          <Route index element={<PricingWorks />} />
          <Route path=":state" element={<PricingWorks />} />
        </Route>

        <Route
          path="/aboutus/state/"
          element={<DefaultLayout route={"aboutus"} />}
        >
          <Route index element={<AboutPage />} />
          <Route path=":state" element={<AboutPage />} />
        </Route>

        <Route path="/media/state/" element={<DefaultLayout route={"media"} />}>
          <Route index element={<MediaPage />} />
          <Route path=":state" element={<MediaPage />} />
        </Route>

        <Route
          path="/advertise/state/"
          element={<DefaultLayout route={"advertise"} />}
        >
          <Route index element={<AdvertisePage />} />
          <Route path=":state" element={<AdvertisePage />} />
        </Route>

        <Route
          path="/contact/state/"
          element={<DefaultLayout route={"contact"} />}
        >
          <Route index element={<ContactPage />} />
          <Route path=":state" element={<ContactPage />} />
        </Route>

        <Route
          path="/privacy/state/"
          element={<DefaultLayout route={"privacy"} />}
        >
          <Route index element={<PrivacyPage />} />
          <Route path=":state" element={<PrivacyPage />} />
        </Route>

        <Route path="/terms/state/" element={<DefaultLayout route={"terms"} />}>
          <Route index element={<TermsPage />} />
          <Route path=":state" element={<TermsPage />} />
        </Route>

        <Route
          path="/public-data/state/"
          element={<DefaultLayout route={"public-data"} />}
        >
          <Route index element={<PublicDataPage />} />
          <Route path=":state" element={<PublicDataPage />} />
        </Route>
      </Route>

      {/* <Route path="*" element={<ErrorPage />} /> */}

      <Route path="*" element={<ErrorPage />} />
    </>
  )
);
