import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container, Skeleton, Stack, Typography } from "@mui/material";
import SelectDemo from "@/components/Dropdown/SelectDemo";
import { colors } from "@/constants/colors";

import {
  useGetOfferMakeQuery,
  useGetOffersQuery,
} from "@/app/features/offer/offerApiSlice";
import OfferLisiting from "@/sections/SelectOfferTracker/OfferListing";
import AdCarousel from "@/components/ad-caraousel";

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: "1rem",
  marginBottom: "1rem",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },

  "& > div": {
    flex: 1,
  },
}));

const SearchOffersInputWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid #070F15",
  display: "flex",
  alignItems: "center",
}));

const SearchOffersInput = styled("input")(({ theme }) => ({
  outline: "none",
  border: "none",
  width: "100%",
  height: "100%",
  padding: "8px 16px",
  borderRadius: "4px",
  fontSize: "16px",
  lineHeight: "160%",
}));

const SearchOffersInputIcon = styled(SearchIcon)(({ theme }) => ({
  position: "absolute",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)",
}));

const SelectOfferForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { state } = useParams();

  const make = searchParams.get("make");
  const offer = searchParams.get("offer");

  const [search, setSearch] = useState("");

  const {
    data: carMake,
    isLoading,
    isError,
  } = useGetOfferMakeQuery(
    {
      state: state,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: offers,
    isLoading: offersLoading,
    isError: offersError,
  } = useGetOffersQuery(
    {
      make: make === "All" ? "" : make,
      offer: offer === "All" ? "" : offer,
      search: search.trim(),
      state: state,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleSearch = (e) => setSearch(e.target.value);

  const onChange = (name, value) => {
    setSearchParams({
      make: make,
      offer: offer,
      [name]: value,
    });
  };

  if (isError) {
    return <Skeleton variant="rectangular" width={"100%"} height={200} />;
  }

  return (
    <>
      {state && (
        <Box mb="16px">
          <AdCarousel bannerType="TOP_BANNER" />
        </Box>
      )}

      <Typography
        component="h5"
        mb={1}
        sx={{
          padding: 0,
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.01em",
        }}
      >
        Please make a selection
      </Typography>

      {isLoading ? (
        <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
          <Skeleton variant="rectangular" width={"100%"} height={50} />
        </Container>
      ) : (
        <StyledStack>
          <SelectDemo
            label="Make"
            name="make"
            defaultOption={["All"]}
            options={carMake?.data}
            background={colors[0]}
            onChange={onChange}
            value={make}
          />
          <SelectDemo
            label="Term"
            name="offer"
            defaultOption={[]}
            options={["All", "Current", "Expired"]}
            background={colors[0]}
            onChange={onChange}
            value={offer}
          />
          <SearchOffersInputWrapper>
            <SearchOffersInput
              name="search"
              placeholder="Search Offers"
              onChange={handleSearch}
            />
            <SearchOffersInputIcon />
          </SearchOffersInputWrapper>
        </StyledStack>
      )}
      <OfferLisiting
        isLoading={offersLoading}
        error={offersError}
        items={offers?.data?.offerTrackerList}
      />

      {state && (
        <Box mt={"32px"}>
          <AdCarousel bannerType="BOTTOM_BANNER" />
        </Box>
      )}
    </>
  );
};

export default SelectOfferForm;
