import { Box, Button, Stack } from "@mui/material";
import { useTransition, animated } from "react-spring";
import { Scrollbar } from "@/components/scrollbar";
import { useLocation, Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { LocationIcon } from "@/assets/icons";
import { companyoptions, resourcesoptions } from "@/constants/links";
import { stateMap } from "@/constants/states";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsSelectStateModalVisible,
  toggleSelectedStateModal,
} from "@/app/features/userState/userStateSlice";

const MenuButtonStyles = styled(Button)(({ theme, isOpen }) => ({
  color: isOpen ? "#4a98d3" : "#fff",
  textDecoration: "none",
  background: "transparent",
  border: "none",
  outline: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
  lineHeight: "20px",
  cursor: "pointer",
  padding: "16px",
  fontWeight: 400,
  width: "100%",
  justifyContent: "space-between",
}));

// const MenuButtonStyles = styled("button")`
//   color: #fff;
//   text-decoration: none;
//   background: transparent;
//   border: none;
//   outline: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 16px;
//   line-height: 20px;
//   cursor: pointer;
//   padding: 16px;
// `;

const LinkStyles = styled(Link)(({ theme, active, isFirst }) => ({
  // color: active ? "#4a98d3" : "#fff",
  color: "#FBFCFF",
  textDecoration: "none",
  fontSize: "16px",
  padding: "16px",
  lineHeight: "20px",
  borderBottom: "1px solid #fff",
  fontWeight: active ? 600 : 400,

  borderTop: isFirst ? "1px solid #fff" : "",
  transition: "hight 0.6s ease-in-out",

  "&:hover": {
    // color: "#4a98d3",
    fontWeight: active ? 600 : 400,
  },
}));

const DropDownLinkStyles = styled(Link)(({ theme, active }) => ({
  // color: active ? "#4a98d3" : "#fff",
  color: "#FBFCFF",
  textDecoration: "none",
  fontSize: "16px",
  padding: "8px 16px",
  lineHeight: "20px",
  fontWeight: active ? 600 : 400,

  "&:hover": {
    // color: "#4a98d3",
    fontWeight: active ? 600 : 400,
  },
}));

const LocationDropdownSelectorButton = styled(Button)(({ theme }) => ({
  // color: active ? "#4a98d3" : "#fff",
  color: "#fff",
  textDecoration: "none",
  fontSize: "16px",
  padding: "16px",
  lineHeight: "20px",
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "8px",

  "& > svg": {
    width: "12px",
    height: "18px",
  },

  // "&:hover": {
  //   color: "#4a98d3",
  // },
}));

const LogoStyles = styled("img")`
  display: block;
  max-width: 150px;
  /* margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
`;

export const SideNav = (props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const params = useParams();

  const selectedState = params.state;

  const isSelectStateModalVisible = useSelector(
    selectIsSelectStateModalVisible
  );

  const dispatch = useDispatch();

  const transitions = useTransition(open, {
    from: { height: "0%" },
    enter: { height: "100%" },
    leave: { height: "0%" },
    config: { tension: 280, friction: 40 },
  });

  const content = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.1000",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack>
            <LinkStyles
              to="/price-tracker/state"
              active={location.pathname.includes("price-tracker")}
              isFirst={true}
            >
              Price Tracker
            </LinkStyles>
            <LinkStyles
              to="/offer-tracker/state"
              active={location.pathname.includes("offer-tracker")}
            >
              Offer & Deals
            </LinkStyles>
            <SideNavDropdown name="Resources" options={resourcesoptions} />

            <SideNavDropdown name="Company" options={companyoptions} />

            <LocationDropdownSelectorButton
              onClick={() => {
                onClose();
                dispatch(toggleSelectedStateModal());
              }}
            >
              <LocationIcon height={18} />
              {stateMap[selectedState]}
            </LocationDropdownSelectorButton>
          </Stack>
        </Box>
      </Box>
    </Scrollbar>
  );

  // return (
  //   <Collapse in={open} timeout="auto" unmountOnExit>
  //     <Box
  //       className="menu-box"
  //       sx={{
  //         backgroundColor: "neutral.1000",
  //         color: "common.white",
  //         width: "100%",
  //         height: "100dvh",
  //       }}
  //     >
  //       {content}
  //     </Box>
  //   </Collapse>
  // );

  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          className="menu-box"
          style={{
            ...styles,
            backgroundColor: "#000",
            color: "common.white",
            width: "100%",
            position: "fixed",

            zIndex: 1200,
          }}
        >
          {content}
        </animated.div>
      )
  );
};

SideNav.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const SideNavDropdown = ({ name, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        borderBottom: "1px solid #fff",
        transition: "hight 0.6s ease-in-out",
      }}
    >
      <MenuButtonStyles
        onClick={toggleDropdown}
        aria-expanded={isOpen}
        aria-controls="dropdown-menu"
        isOpen={isOpen}
      >
        {name}
        <ChevronDownIcon
          style={{
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.2s ease",
          }}
          width={20}
        />
      </MenuButtonStyles>

      {isOpen && (
        <Stack
          sx={{
            pl: "16px",
            borderBottom: "1px solid #fff",
            paddingBottom: "8px",
          }}
          id="dropdown-menu"
          role="menu"
        >
          {options.map((option) => (
            <DropDownLinkStyles
              to={option.link}
              active={location.pathname.includes(option.link)}
            >
              {option.name}
            </DropDownLinkStyles>
          ))}
        </Stack>
      )}
    </div>
  );
};
