import { useGetTermsQuery } from "@/app/features/config/configApiSlice";
import ShowHtmlContent from "@/components/ShowHtmlContent";
import { useAnalyticsTitle } from "@/hooks/useAnalyticsTitle";
import { Container, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import AdImage from "@/assets/ad/big.png";
import { Helmet } from "react-helmet-async";
import pageContent from "@/constants/pageContent";

const StyledAdImage = styled("img")(({ theme }) => ({
  width: "100%",
  marginTop: "16px",
  marginBottom: "16px",
  height: "100px",
  [theme.breakpoints.up("md")]: {
    height: "90px",
    marginBottom: "24px",
    marginTop: "20px",
  },
}));

const StyledAdImage2 = styled("img")(({ theme }) => ({
  width: "100%",
  marginTop: "20px",
  height: "100px",
  [theme.breakpoints.up("md")]: {
    height: "90px",
    marginTop: "20px",
  },
}));

const TermsPage = () => {
  const { data, isLoading, isError } = useGetTermsQuery();

  useAnalyticsTitle(pageContent.terms.title);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      </Container>
    );
  }

  if (isError) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "10px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      {/* <StyledAdImage src={AdImage} alt="add" /> */}

      <Helmet>
        <title>{pageContent.terms.title}</title>
        {pageContent.terms.description && (
          <meta name="description" content={pageContent.terms.description} />
        )}
      </Helmet>

      <ShowHtmlContent content={data.termsConditions.termsConditionsDetail} />

      {/* <StyledAdImage2 src={AdImage} alt="add" /> */}
    </Container>
  );
};

export default TermsPage;
