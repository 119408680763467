import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import {
  ArchiveBoxXMarkIcon,
  ChevronDownIcon,
  PencilIcon,
  Square2StackIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const MenuButtonStyles = styled(MenuButton)`
  color: #fff;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: #4a98d3;
  }
`;

const MenuItemsStyles = styled(MenuItems)`
  background-color: #000000;

  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  padding: 24px !important;
  /* width: 200px; */
  padding-top: 16px !important;

  gap: 16px;

  z-index: 999;
  margin-top: 20px !important;
  /* border-top: 1px solid #4a98d3 !important; */

  &::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    background-color: #4a98d3;
    top: 0px;
    left: 50%;
  }
`;

// const StyledButton = styled(Link)`
//   font-size: 16px;
//   line-height: 24px;
//   cursor: pointer;

//   text-decoration: none;
//   background: transparent;
//   border: none;
//   outline: none;
//   color: #fff;
//   padding: 5px 10px;

//   &:hover {
//     color: #4a98d3;
//   }
// `;

const StyledButton = styled(Link)(({ theme, active }) => ({
  fontSize: "16px",
  lineHeight: "20px",
  cursor: "pointer",
  background: "transparent",
  border: "none",
  outline: "none",
  // padding: "5px 10px",
  // color: active ? "#4a98d3" : "#FBFCFF",
  color: "#FBFCFF",
  textDecoration: "none",
  fontWeight: active ? 600 : 400,

  "&:hover": {
    color: "#4a98d3",
  },
}));

export function MenuDropdown({ name, options }) {
  const location = useLocation();

  return (
    <Menu>
      <MenuButtonStyles>
        {name}
        <ChevronDownIcon width={18} style={{ padding: 0 }} />
      </MenuButtonStyles>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItemsStyles
          anchor="bottom end"
          className="w-52 origin-top-right rounded-xl border border-white/5 bg-white/5 p-1 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none"
        >
          {options.map((option, index) => (
            <MenuItem key={index}>
              <StyledButton
                to={option.link}
                active={location.pathname.includes(option.link)}
              >
                {option.name}
              </StyledButton>
            </MenuItem>
          ))}
        </MenuItemsStyles>
      </Transition>
    </Menu>
  );
}
