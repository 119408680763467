import { Button, Stack } from "@mui/material";

import { PlusCircleIcon } from "lucide-react";

import { styled } from "@mui/material/styles";
import SelectCarDetails from "@/sections/DefaultSelectForm/DefaultSelectCarDetails";
import ChartWrapper from "@/sections/DefaultSelectForm/ChartWrapper";
import { DEFAULT_STATE } from "@/constants/states";

const StyledPlusCircleIcon = styled(PlusCircleIcon)(({ theme, disabled }) => ({
  height: "25px",
  color: "#000",
  opacity: disabled ? 0.3 : 1,
}));

const DefaultSelectCarForm = () => {
  return (
    <>
      <SelectCarDetails
        make={"All"}
        model={"All"}
        grade={"All"}
        state={DEFAULT_STATE}
        timeline="1Y"
        index={0}
        key={0}
      />

      <Stack
        alignItems="flex-end"
        justifyContent="flex-end"
        sx={{
          mt: "8px",
        }}
        direction="row"
      >
        <Button
          // onClick={() => handleCarAddition()}
          sx={{
            color: "#000",
            display: "flex",
            alignItems: "center",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            gap: "8px",
            "& :disabled": {
              cursor: "not-allowed",
            },
          }}
          // disabled={make.length === 4}
        >
          <StyledPlusCircleIcon />
          <span>Compare Models</span>
        </Button>
      </Stack>

      <ChartWrapper />
    </>
  );
};

export default DefaultSelectCarForm;
