import { DEFAULT__CHART_FILTER } from "@/constants/filters";
import { create } from "zustand";

const useCarStore = create((set) => ({
  chartData: [],
  timeline: DEFAULT__CHART_FILTER,
  loading: true,

  setChartData: (chartData) =>
    set((state) => {
      let temp = [];
      temp = [...state.chartData];
      temp[chartData.value] = chartData;

      return { ...state, chartData: temp, loading: false };
    }),

  setTimeline: (timeline) =>
    set((state) => ({
      ...state,
      timeline: timeline,
    })),

  setLoading: () => set((state) => ({ loading: !state.loading })),

  resetChart: () => {
    set((state) => {
      const temp = state.chartData.length > 0 ? [state.chartData[0]] : [];

      return {
        chartData: temp,
      };
    });
  },

  removeCarFromChart: (indexToRemove) =>
    set((state) => {
      if (indexToRemove === 0) return state;

      const tempChartData = state.chartData;
      const newChartData = tempChartData.filter(
        (car, index) => index !== indexToRemove
      );

      return {
        ...state,
        chartData: newChartData,
      };
    }),
}));

export default useCarStore;
