import { apiSlice } from "../../api/apiSlice";

export const advertisementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    keepUnusedDataFor: 1,
    getAdvertisementByState: builder.query({
      query: ({ stateCode, bannerType }) =>
        `/api/v1/advertisement/active?state=${stateCode}&bannerType=${bannerType}`,
    }),
  }),
});

export const { useGetAdvertisementByStateQuery } = advertisementApiSlice;
